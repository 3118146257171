import React, {useEffect, useState} from "react";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import {useCheckElevatorList} from "~/data/check/elevator/use-check-elevator";
import {CostIntegratedRequest, CostIntegratedResponse} from "~/data/cost/integrated/integrated.interface";
import {httpRequest} from "~/fetch/common/http-request";
import {calculatePercentage} from "~/utills/calculatePercentage";

import styles from "./pc-dashboard.page.module.scss";

const cx = classNames.bind(styles);

const CheckStatusMember = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [total, setTotal] = useState<number>(0);
    const [complete, setComplete] = useState<number>(0);
    const [incomplete, setIncomplete] = useState<number>(0);
    const {summary} = useCheckElevatorList({
        perPage: 1000,
        page: 1,
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
    });

    useEffect(() => {
        httpRequest<CostIntegratedRequest, CostIntegratedResponse>({
            method: "GET",
            url: "/lotte/fms/performance",
            params: {
                startDate: dayjs().format("YYYY-MM-DD"),
                endDate: dayjs().format("YYYY-MM-DD"),
            },
        })
            .submit()
            .then((res: any) => {
                let total = 0;
                let complete = 0;
                let incomplete = 0;
                res.data.lotteFmsPerformanceRatioTotal.map((row: any) => {
                    if (row.sectorId !== 12) {
                        total += row.statusTotal;
                        complete += row.statusSuccess;
                        incomplete += row.statusIncomplete;
                    }
                });
                setTotal(total);
                setComplete(complete);
                setIncomplete(incomplete);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <div className={cx("card", "check-status")}>
            <div className={cx("loading", {active: isLoading})}>
                <div className={cx("loading-text")}>Loading</div>
            </div>
            <div className={cx("body")}>
                <div className={cx("check-analytics")}>
                    <div className={cx("analytics-area")}>
                        <ul className={cx("analytics-list")}>
                            <li className={cx("analytics-item")}>
                                <span className={cx("analytics-item-title")}>점검현황</span>
                                <span className={cx("analytics-item-desc")}>
                                    {calculatePercentage(complete, total)}%
                                </span>
                                <span className={cx("analytics-item-tag")}>이행률</span>
                                <span className={cx("analytics-item-desc")}>{total.toLocaleString()}건</span>
                                <span className={cx("analytics-item-tag")}>전체</span>
                            </li>
                            <li className={cx("analytics-item")}>
                                <span className={cx("analytics-item-title")} />
                                <span className={cx("analytics-item-desc")}>{complete.toLocaleString()}건</span>
                                <span className={cx("analytics-item-tag")}>완료</span>
                                <span className={cx("analytics-item-desc")}>{incomplete.toLocaleString()}건</span>
                                <span className={cx("analytics-item-tag")}>미완료</span>
                            </li>
                            <li className={cx("analytics-item")}>
                                <span className={cx("analytics-item-title")} />
                                <div className={cx("analytics-item-chart")}>
                                    <div
                                        className={cx("analytics-item-chart-bar")}
                                        style={{width: `${calculatePercentage(complete, total)}%`}}
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className={cx("analytics-area")}>
                        <ul className={cx("analytics-list")}>
                            <li className={cx("analytics-item")}>
                                <span className={cx("analytics-item-title")}>승강기점검</span>
                                <span className={cx("analytics-item-desc")}>{summary.REQUEST}건</span>
                                <span className={cx("analytics-item-tag")}>접수</span>
                                <span className={cx("analytics-item-desc")}>{summary.COMPLETED}건</span>
                                <span className={cx("analytics-item-tag")}>완료</span>
                                <span className={cx("analytics-item-desc")}>{summary.penalty_above}건</span>
                                <span className={cx("analytics-item-tag")}>패널티</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {CheckStatusMember};
