import {ChangeEvent, useEffect, useState} from "react";
import {App, Input, Select, Table} from "antd";
import classNames from "classnames/bind";
import Swal from "sweetalert2";

import Button from "~/components/button/button";
import {CenterModal} from "~/components/modal/center-modal";
import {useSettingWorkPlan} from "~/data/work-plan/setting/use-setting-work-plan";
import {useCommonWorkPlan} from "~/data/work-plan/use-common-work-plan";

import {WorkListType} from "../work-plan.interface";
import {WorkModalWorkModalProps} from "./work-plan-work.modal.interface";

import styles from "./work-plan-work.modal.module.scss";

const cx = classNames.bind(styles);

const WorkPlanWorkModal = ({close, isShow, refresh}: WorkModalWorkModalProps) => {
    const [list, setList] = useState<WorkListType[]>([]);

    const {submitList, workList, isLoading} = useCommonWorkPlan();
    const {update, deleteWork} = useSettingWorkPlan(list);

    useEffect(() => {
        submitList();
    }, []);

    useEffect(() => {
        setList(workList);
    }, [workList]);

    const handleInput = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        setList((prev) =>
            prev.map((item, idx) => {
                if (idx === index) {
                    return {
                        ...item,
                        name: e.target.value,
                    };
                }
                return item;
            }),
        );
    };

    const handleSelect = (e: boolean, index: number) => {
        setList((prev) =>
            prev.map((item, idx) => {
                if (idx === index) {
                    return {
                        ...item,
                        detailYn: e,
                    };
                }
                return item;
            }),
        );
    };

    const handleAddList = () => {
        setList((prev) => [
            ...prev,
            {
                name: "",
                defaultYn: false,
                detailYn: false,
                sectorYn: false,
            },
        ]);
    };

    return (
        <CenterModal
            isShow={isShow}
            close={close}
            closeIcon={false}
            extraButtons={[
                <Button
                    label="저장하기"
                    key={"confirm"}
                    onClick={() => {
                        Swal.fire({
                            text: "저장하시겠습니까?",
                            showCancelButton: true,
                            confirmButtonText: "확인",
                            cancelButtonText: "취소",
                        })
                            .then(async (info) => {
                                if (info.isConfirmed) await update();
                            })
                            .finally(() => {
                                close();
                                setTimeout(refresh, 500);
                            });
                    }}
                />,
            ]}
            width={600}
            zIndex={11000}
            title={"설정"}
        >
            <App>
                <div className={cx("content")}>
                    <Table
                        className={cx("table")}
                        rowKey={"index"}
                        pagination={false}
                        bordered
                        dataSource={list.map((data, index) => ({
                            ...data,
                            index: index + 1,
                        }))}
                        columns={[
                            {
                                title: "No",
                                dataIndex: "index",
                                key: "index",
                                align: "center",
                                width: "5%",
                            },
                            {
                                title: "업무",
                                dataIndex: "name",
                                key: "name",
                                align: "center",
                                width: "55%",
                                render: (value, _, index) => (
                                    <Input
                                        className={cx("inputs")}
                                        value={value}
                                        onChange={(e) => handleInput(e, index)}
                                    />
                                ),
                            },
                            {
                                title: "상세입력",
                                dataIndex: "detailYn",
                                key: "detailYn",
                                align: "center",
                                width: "20%",
                                render: (value, _, index) => (
                                    <Select
                                        className={cx("selects")}
                                        value={value}
                                        onChange={(e) => handleSelect(e, index)}
                                        options={[
                                            {
                                                label: "사용",
                                                value: true,
                                            },
                                            {
                                                label: "미사용",
                                                value: false,
                                            },
                                        ]}
                                    />
                                ),
                            },
                            {
                                title: <Button label={"추가"} color={"primary"} onClick={handleAddList} />,
                                align: "center",
                                width: "10%",
                                render: (_, record) => (
                                    <Button
                                        label={"삭제"}
                                        onClick={() => {
                                            Swal.fire({
                                                html: "삭제하시겠습니까?",
                                                showCancelButton: true,
                                                confirmButtonText: "확인",
                                                cancelButtonText: "취소",
                                            }).then(async (info) => {
                                                if (info.isConfirmed) {
                                                    await deleteWork(record);
                                                    setTimeout(submitList, 300);
                                                    setTimeout(refresh, 500);
                                                }
                                            });
                                        }}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
            </App>
        </CenterModal>
    );
};

export {WorkPlanWorkModal};
