import React, {Fragment, useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {faBars, faRightFromBracket} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import Swal from "sweetalert2";

import {
    hiddenMenuPathForManager,
    hiddenMenuPathForMember,
    hiddenMenuPathNameForMember,
    member,
} from "~/components/sidebar/menus";
import {useUser} from "~/data/user";
import {usePlatform} from "~/utills/use-platform";

import {HeaderProps} from "./header.interface";
import Logo from "./lottemart logo.png";

import styles from "./header.module.scss";
const cx = classNames.bind(styles);

export const Header = (props: HeaderProps) => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const navigate = useNavigate();
    const {logout} = useUser();

    const handleLogoClick = () => (window.location.href = "/");

    const {platform} = usePlatform();
    const {user} = useUser();

    const handleLogout = () => {
        if (platform === "mobile") {
            Swal.fire({
                title: "로그아웃 하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "로그아웃",
                cancelButtonText: "돌아가기",
            }).then((result) => {
                if (result.isConfirmed) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.flutter_inappwebview.callHandler("handlerWithArgs", {type: 200});
                }
            });
        } else {
            logout();
        }
    };

    const {t, i18n} = useTranslation();

    const changeLanguage = () => {
        if (i18n.language === "ko") i18n.changeLanguage("en");
        else i18n.changeLanguage("ko");
    };

    const pageMove = (path: string) => {
        try {
            const url = new URL(path);
            window.open(url, "_blank");
        } catch (error) {
            navigate(path);
        }
    };

    const location = useLocation();

    useEffect(() => {
        setIsActive(false);
    }, [location.pathname, location.search]);

    return (
        <div className={cx("container", {mobile: platform === "mobile"})}>
            <div className={cx("logo-container")}>
                <a href="javascript:void(0);" onClick={handleLogoClick}>
                    <img src={Logo} alt="롯데마트 로고" className={cx("logo")} />
                </a>
            </div>
            <div className={cx("contents-container", {mobile: platform === "mobile"})}>
                <ul className={cx("list")}>
                    {platform === "mobile" && (
                        <li className={cx("item")}>
                            <button onClick={props.toggleSidebar} className={cx("menu-button")}>
                                <FontAwesomeIcon icon={faBars} className={cx("icon")} />
                            </button>
                        </li>
                    )}
                    {member.map((item, index) => (
                        <li
                            className={cx("item", "quick-menu", {
                                hidden:
                                    user?.division === "MANAGER" &&
                                    hiddenMenuPathForManager.indexOf(item.path as string) > -1,
                            })}
                            key={index}
                        >
                            <a href={"javascript:void(0);"} onClick={() => item.path && pageMove(item.path)}>
                                <span className={cx("name")}>{t(item.name) || item.name}</span>
                            </a>
                            {item?.children && (
                                <div className={cx("sub-item-container")}>
                                    <ul>
                                        {item.children?.map((subItem) => {
                                            if (
                                                user?.division === "MANAGER" &&
                                                hiddenMenuPathForManager.indexOf(subItem.path as string) > -1
                                            )
                                                return null;
                                            if (
                                                user?.division === "MEMBER" &&
                                                hiddenMenuPathNameForMember.indexOf(subItem.name as string) > -1
                                            )
                                                return null;
                                            return (
                                                <Fragment key={subItem.name}>
                                                    <li className={cx("sub-item")}>
                                                        <a
                                                            href={"javascript:void(0);"}
                                                            onClick={() => subItem.path && pageMove(subItem.path)}
                                                        >
                                                            <span className={cx("name")}>
                                                                {t(subItem.name) || subItem.name}
                                                            </span>
                                                        </a>
                                                    </li>
                                                    {subItem.children?.map((subItem2) => {
                                                        if (
                                                            user?.division === "MANAGER" &&
                                                            hiddenMenuPathForManager.indexOf(subItem2.path as string) >
                                                                -1
                                                        )
                                                            return null;
                                                        if (
                                                            user?.division === "MEMBER" &&
                                                            hiddenMenuPathForMember.indexOf(subItem2.path as string) >
                                                                -1
                                                        )
                                                            return null;

                                                        return (
                                                            <li className={cx("sub-item", "sub-2")} key={subItem2.name}>
                                                                <a
                                                                    href={"javascript:void(0);"}
                                                                    onClick={() =>
                                                                        subItem2.path && pageMove(subItem2.path)
                                                                    }
                                                                >
                                                                    <span className={cx("name")}>
                                                                        - {t(subItem2.name) || subItem2.name}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        );
                                                    })}
                                                </Fragment>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <div className={cx("status-container")}>
                {platform === "mobile" ? (
                    <Fragment>
                        <span>{user?.division === "MEMBER" ? user?.name : user?.buildingName}</span>
                        <button className={cx("logout-button")}>
                            <FontAwesomeIcon icon={faRightFromBracket} className={cx("icon")} onClick={handleLogout} />
                        </button>
                    </Fragment>
                ) : (
                    <div className={cx("x-icon", {active: isActive})} onClick={() => setIsActive((prev) => !prev)}>
                        <div className={cx("bar1")}></div>
                        <div className={cx("bar2")}></div>
                        <div className={cx("bar3")}></div>
                    </div>
                )}
            </div>
            {isActive && <div className={cx("full-nav-bg")} onClick={() => setIsActive(false)} />}
            <div className={cx("full-nav", {active: isActive})}>
                <ul>
                    {member.map((item, index) => (
                        <li
                            key={item.name}
                            className={cx({
                                hidden:
                                    user?.division === "MANAGER" &&
                                    hiddenMenuPathForManager.indexOf(item.path as string) > -1,
                            })}
                        >
                            <a href={"javascript:void(0);"} onClick={() => item.path && pageMove(item.path)}>
                                <span className={cx("name")}>{t(item.name)}</span>
                            </a>
                            {item?.children && (
                                <div className={cx("sub-menu-container")}>
                                    <ul>
                                        {item.children?.map((subItem) => {
                                            if (
                                                user?.division === "MANAGER" &&
                                                hiddenMenuPathForManager.indexOf(subItem.path as string) > -1
                                            )
                                                return null;
                                            if (
                                                user?.division === "MEMBER" &&
                                                hiddenMenuPathNameForMember.indexOf(subItem.name as string) > -1
                                            )
                                                return null;
                                            return (
                                                <Fragment key={subItem.name}>
                                                    <li>
                                                        <a
                                                            href={"javascript:void(0);"}
                                                            onClick={() => subItem.path && pageMove(subItem.path)}
                                                        >
                                                            <span className={cx("name")}>
                                                                {t(subItem.name) || subItem.name}
                                                            </span>
                                                        </a>
                                                    </li>
                                                    {subItem.children?.map((subItem2) => {
                                                        if (
                                                            user?.division === "MANAGER" &&
                                                            hiddenMenuPathForManager.indexOf(subItem2.path as string) >
                                                                -1
                                                        )
                                                            return null;
                                                        if (
                                                            user?.division === "MEMBER" &&
                                                            hiddenMenuPathForMember.indexOf(subItem2.path as string) >
                                                                -1
                                                        )
                                                            return null;
                                                        return (
                                                            <li key={subItem2.name} className={cx("sub")}>
                                                                <a
                                                                    href={"javascript:void(0);"}
                                                                    onClick={() =>
                                                                        subItem2.path && pageMove(subItem2.path)
                                                                    }
                                                                >
                                                                    <span className={cx("name")}>
                                                                        {t(subItem2.name) || subItem2.name}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        );
                                                    })}
                                                </Fragment>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}
                        </li>
                    ))}
                    <li>
                        <a>
                            <span className={cx("name")}>{t("user.userinfo")}</span>
                        </a>
                        <div className={cx("sub-menu-container")}>
                            <ul>
                                <li>
                                    <span>
                                        {user?.division === "MEMBER" ? user?.name : user?.buildingName} (
                                        {user?.username})
                                    </span>
                                </li>
                                <li>
                                    <a>
                                        <span className={cx("name")} onClick={handleLogout}>
                                            {t("user.logout")}
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <span className={cx("name")} onClick={changeLanguage}>
                                            {t("language")}
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};
