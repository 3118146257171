import {createBrowserRouter} from "react-router-dom";

import {InquiryBoardPage} from "~/pages/board/inquiry/inquiry-board.page";
import {NoticeBoardPage} from "~/pages/board/notice/notice-board.page";
import {CheckApprovalPage} from "~/pages/check/approval/approval.page";
import {ElevatorCheckPage} from "~/pages/check/elevator/elevator-check.page";
import {ElevatorCheckQr} from "~/pages/check/elevator/qr/elevator-check-qr";
import {ElevatorCheckQrList} from "~/pages/check/elevator/qr/elevator-check-qr-list";
import {FormRoutePage} from "~/pages/check/form/form-route.page";
import {CheckHistoryPage} from "~/pages/check/history/check-history.page";
import {InstructionPage} from "~/pages/check/instruction/instruction.page";
import {ReportPage} from "~/pages/check/report/report.page";
import {CostFacilityMaintenancePage} from "~/pages/cost/facility-maintenance/cost-facility-maintenance.page";
import {CostIntegratedPage} from "~/pages/cost/integrated/cost-integrated.page";
import {CostMaintenanceAndLightingPage} from "~/pages/cost/maintenance-lighting/cost-maintenance-lighting.page";
import {CostMonthlyPage} from "~/pages/cost/monthly/cost-monthly.page";
import {CostTrainerPage} from "~/pages/cost/trainer/cost-trainer.page";
import {DashboardPage} from "~/pages/dashboard/dashboard.page";
import {EquipmentPage} from "~/pages/equipment/equipment.page";
import {Error404} from "~/pages/error/404.page";
import {InspectionRateStatusPage} from "~/pages/inspection-rate/inspection-rate-status.page";
import {HeaderWithSidebar} from "~/pages/layout/header-with-sidebar";
import {LoginPage} from "~/pages/login/login.page";
import {MergeBoardPage} from "~/pages/merge/merge-board.page";
import {AllListPage} from "~/pages/mobile/check/all-list.page";
import {CheckPage} from "~/pages/mobile/check/check.page";
import {CheckTableView} from "~/pages/mobile/check/check-table-view.page";
import {MobileCheckElevatorListPage} from "~/pages/mobile/check/elevator/list.page";
import {QrPage} from "~/pages/mobile/check/elevator/qr.page";
import {QuickPage} from "~/pages/mobile/check/elevator/quick.page";
import {MobileCheckElevatorRequestEditPage} from "~/pages/mobile/check/elevator/request.edit.page";
import {MobileCheckElevatorRequestPage} from "~/pages/mobile/check/elevator/request.page";
import {ListPage} from "~/pages/mobile/check/list.page";
import {MobileEquipmentDetailPage} from "~/pages/mobile/equipment/detail.page";
import {MobileEquipmentHistoryEditPage} from "~/pages/mobile/equipment/history-edit-detail.page";
import {MobileEquipmentHistorySubmitPage} from "~/pages/mobile/equipment/history-submit.page";
import {MobileEquipmentListPage} from "~/pages/mobile/equipment/list.page";
import {MobileEquipmentProcessListPage} from "~/pages/mobile/equipment/process-list.page";
import {MobileEquipmentSearchPage} from "~/pages/mobile/equipment/search.page";
import {WorkPlanPage} from "~/pages/work-plan/work-plan.page";

const router = createBrowserRouter([
    {
        path: "/",
        element: <HeaderWithSidebar />,
        errorElement: <Error404 />,
        children: [
            {
                path: "/",
                element: <DashboardPage />,
            },
            {
                path: "/check/:type/instruction",
                element: <InstructionPage />,
            },
            {
                path: "/check/:type/approval",
                element: <CheckApprovalPage />,
            },
            /** **** [] 보고서조회 **** */
            {
                path: "/check/:type/report",
                element: <ReportPage />,
            },
            {
                path: "/check/:type/form/:dev?",
                element: <FormRoutePage />,
            },
            {
                path: "/check/:type/history/:dev?",
                element: <CheckHistoryPage />,
            },
            {
                path: "/check/elevator",
                element: <ElevatorCheckPage />,
            },

            /** 헤더와 사이드바를 가진 페이지 추가는 여기 아래에 하면 됩니다. */
            {
                path: "/equipment",
                element: <EquipmentPage />,
            },
            {
                path: "/merge",
                element: <MergeBoardPage />,
            },
            {
                path: "/cost/integrated",
                element: <CostIntegratedPage />,
            },
            {
                path: "/cost/maintenance/:trainerSectorId?/:dates?",
                element: <CostMaintenanceAndLightingPage />,
            },
            {
                path: "/cost/facility/:typeId?/:trainerSectorId?/:dates?",
                element: <CostFacilityMaintenancePage />,
            },
            {
                path: "/cost/trainer/:dates?",
                element: <CostTrainerPage />,
            },
            {
                path: "/cost/monthly",
                element: <CostMonthlyPage />,
            },
            {
                path: "/board/inquiry",
                element: <InquiryBoardPage />,
            },
            {
                path: "/board/notice",
                element: <NoticeBoardPage />,
            },
            {
                path: "/plan",
                element: <WorkPlanPage />,
            },
            {
                path: "/inspection-rate",
                element: <InspectionRateStatusPage />,
            },
            {
                path: "/mobile/check/elevator",
                element: <QuickPage />,
            },
            {
                path: "/mobile/check/elevator/request/edit/:id",
                element: <MobileCheckElevatorRequestEditPage />,
            },
            {
                path: "/mobile/check/elevator/list",
                element: <MobileCheckElevatorListPage />,
            },
            {
                path: "/mobile/check/elevator/qr",
                element: <QrPage />,
            },
            {
                path: "/mobile/check/list",
                element: <ListPage />,
            },
            {
                path: "/mobile/check/all-list",
                element: <AllListPage />,
            },
            {
                path: "/mobile/check/view/:regionId/:checkFormArr/:date",
                element: <CheckPage />,
            },
            {
                path: "/mobile/check/table/:regionId/:checkFormArr/:date",
                element: <CheckTableView />,
            },
            {
                path: "/mobile/equipment",
                element: <MobileEquipmentListPage />,
            },
            {
                path: "/mobile/equipment/search",
                element: <MobileEquipmentSearchPage />,
            },
            {
                path: "/mobile/equipment/:searchProcessCode",
                element: <MobileEquipmentProcessListPage />,
            },
            {
                path: "/mobile/equipment/detail/:companyId/:buildingId/:machineId",
                element: <MobileEquipmentDetailPage />,
            },
            {
                path: "/mobile/equipment/detail/request/:companyId/:buildingId/:machineId",
                element: <MobileEquipmentHistorySubmitPage />,
            },
            {
                path: "/mobile/equipment/detail/edit/:companyId/:buildingId/:machineId/:machineHistoryId",
                element: <MobileEquipmentHistoryEditPage />,
            },
        ],
    },
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/qr/check/elevator/:id",
        element: <ElevatorCheckQr />,
        errorElement: <Error404 />,
    },
    {
        path: "/mobile/check/elevator/request/:id",
        element: <MobileCheckElevatorRequestPage />,
        errorElement: <Error404 />,
    },
    {
        path: "/qr/check/elevator/list/:id",
        element: <ElevatorCheckQrList />,
        errorElement: <Error404 />,
    },
]);

export {router};
