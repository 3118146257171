import React, {Fragment, useEffect, useMemo, useState} from "react";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import {useBoardList} from "~/data/board/use-board-list";
import {useMergeBoardList} from "~/data/merge/board";
import {useUser} from "~/data/user";
import {useWeather} from "~/hooks/use-weather/use-weather";
import {InquiryBoardModal} from "~/pages/board/inquiry/modal/inquiry-board.modal";
import {NoticeBoardModal} from "~/pages/board/notice/modal/notice-board.modal";
import {MergeBoardAnswerModal} from "~/pages/merge/answer/merge-board-answer.modal";
import {MergeBoardDetailModal} from "~/pages/merge/detail/merge-board-detail.modal";

import {CheckStatusManager} from "./check-status-manager";
import {CheckStatusMember} from "./check-status-member";

import styles from "./pc-dashboard.page.module.scss";

const cx = classNames.bind(styles);

const PcDashboardPage = () => {
    const [noticeModal, setNoticeModal] = useState<false | number>(false);
    const [inquiryModal, setInquiryModal] = useState<false | number>(false);
    const [mergeModal, setMergeModal] = useState<false | number>(false);
    const today = useMemo(() => dayjs(), []);

    const {user} = useUser();
    const {weather, weatherLoading} = useWeather();

    const [requestInquiryBoardList, inquiryTableList, isInquiryListLoading] = useBoardList({
        category: "inquiry",
        title: "",
        startDate: today.add(-30, "day").format("YYYY-MM-DD"),
        endDate: today.format("YYYY-MM-DD"),
        page: 1,
        perPage: 5,
    });

    const [requestBoardList, tableList, isListLoading] = useBoardList({
        category: "notice",
        title: "",
        startDate: today.add(-30, "day").format("YYYY-MM-DD"),
        endDate: today.format("YYYY-MM-DD"),
        page: 1,
        perPage: 5,
    });

    const [requestMergeBoardList, mergeBoardList, mergeBoardListLoading] = useMergeBoardList({
        isComplete: "",
        mergeType: "",
        startDate: today.add(-60, "day").format("YYYY-MM-DD"),
        endDate: today.format("YYYY-MM-DD"),
        title: "",
        pageNo: 1,
        recordPerPage: 5,
    });

    useEffect(() => {
        requestBoardList();
        requestInquiryBoardList();
        requestMergeBoardList();
    }, []);

    return (
        <Fragment>
            {noticeModal && (
                <NoticeBoardModal
                    close={() => setNoticeModal(false)}
                    noticeId={noticeModal}
                    refresh={() => {
                        return false;
                    }}
                />
            )}
            {inquiryModal && (
                <InquiryBoardModal
                    close={() => setInquiryModal(false)}
                    noticeId={inquiryModal}
                    refresh={() => {
                        return false;
                    }}
                />
            )}
            {mergeModal &&
                (user?.division === "MEMBER" ? (
                    <MergeBoardDetailModal
                        submit={requestMergeBoardList}
                        noticeMergeId={mergeModal}
                        close={() => setMergeModal(false)}
                    />
                ) : (
                    <MergeBoardAnswerModal
                        submit={requestMergeBoardList}
                        noticeMergeId={mergeModal}
                        close={() => setMergeModal(false)}
                    />
                ))}

            <div>
                <div className={cx("row")}>
                    {user?.division === "MEMBER" && <CheckStatusMember />}
                    {user?.division === "MANAGER" && <CheckStatusManager />}

                    <div className={cx("card")}>
                        <h4 className={cx("title")}>로그인정보</h4>
                        <div className={cx("body")}>
                            <p className={cx("user-info")}>
                                {user?.sectorName}/{user?.buildingName}
                            </p>
                            <p className={cx("user-name")}>
                                {user?.name}({user?.username})
                            </p>
                        </div>
                    </div>
                </div>

                <div className={cx("row")}>
                    <div className={cx("card")}>
                        <div className={cx("loading", {active: isListLoading})}>
                            <div className={cx("loading-text")}>Loading</div>
                        </div>
                        <h4 className={cx("title")}>공지사항</h4>
                        <div className={cx("body")}>
                            {tableList?.list.length === 0 && <h6 className={cx("empty")}>내용이 없습니다.</h6>}
                            <ul className={cx("list")}>
                                {tableList?.list.map((item) => {
                                    return (
                                        <li
                                            key={item.id}
                                            className={cx("item")}
                                            onClick={() => setNoticeModal(item.id)}
                                        >
                                            <span className={cx("board-title")}>{item.title}</span>
                                            <span className={cx("board-date")}>{item.createDate.substring(0, 10)}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className={cx("card")}>
                        {weatherLoading && (
                            <div className={cx("loading", {active: isInquiryListLoading})}>
                                <div className={cx("loading-text")}>Loading</div>
                            </div>
                        )}
                        <h4 className={cx("title")}>
                            <span>날씨정보</span> <small>(현재위치)</small>
                        </h4>
                        <div className={cx("body")}>
                            <h5 className={cx("weather-title")}>
                                {weather?.weather && weather?.weather[0] && weather?.weather[0]?.icon && (
                                    <img
                                        src={`https://openweathermap.org/img/wn/${weather?.weather && weather?.weather[0] && weather?.weather[0]?.icon}@2x.png`}
                                    />
                                )}

                                {weather?.main?.temp && <span>{weather?.main?.temp}°C</span>}
                            </h5>
                            <h6 className={cx("weather-desc")}>
                                {weather?.main && (
                                    <Fragment>
                                        <span>최고 {weather?.main?.temp_max}°C</span>
                                        <span className={cx("mid-point")}>·</span>
                                        <span>
                                            {weather?.weather &&
                                                weather?.weather[0] &&
                                                weather?.weather[0]?.description}
                                        </span>
                                        <span className={cx("mid-point")}>·</span>
                                        <span>최저 {weather?.main?.temp_min}°C</span>
                                        <br />
                                        <span>체감 {weather?.main?.feels_like}°C</span>
                                        <span className={cx("mid-point")}>·</span>
                                        <span>습도 {weather?.main?.humidity}%</span>
                                        <span className={cx("mid-point")}>·</span>
                                        <span>
                                            풍속{" "}
                                            <FontAwesomeIcon
                                                style={{transform: `rotate(${weather?.wind?.deg}deg)`}}
                                                icon={faArrowUp}
                                            />{" "}
                                            {weather?.wind?.speed}m/s
                                        </span>
                                    </Fragment>
                                )}
                            </h6>
                        </div>
                    </div>

                    <div className={cx("card")}>
                        <div className={cx("loading", {active: mergeBoardListLoading})}>
                            <div className={cx("loading-text")}>Loading</div>
                        </div>
                        <h4 className={cx("title")}>취합게시판</h4>
                        <div className={cx("body")}>
                            {mergeBoardList?.resultList.length === 0 && (
                                <h6 className={cx("empty")}>내용이 없습니다.</h6>
                            )}
                            <ul className={cx("list")}>
                                {mergeBoardList?.resultList.map((item: any) => (
                                    <li
                                        key={item.title + item.createDate}
                                        className={cx("item")}
                                        onClick={() => setMergeModal(item.noticeMergeId)}
                                    >
                                        <span className={cx("board-title")}>{item.title}</span>
                                        <span className={cx("board-date")}>{item.createDate.substring(0, 10)}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx("row")}>
                <div className={cx("card")}>
                    <div className={cx("loading", {active: isInquiryListLoading})}>
                        <div className={cx("loading-text")}>Loading</div>
                    </div>
                    <h4 className={cx("title")}>문의게시판</h4>
                    <div className={cx("body")}>
                        {inquiryTableList?.list.length === 0 && <h6 className={cx("empty")}>내용이 없습니다.</h6>}
                        <ul className={cx("list")}>
                            {inquiryTableList?.list.map((item) => {
                                return (
                                    <li key={item.id} className={cx("item")} onClick={() => setInquiryModal(item.id)}>
                                        <span className={cx("board-title")}>{item.title}</span>
                                        <span className={cx("board-date")}>{item.createDate.substring(0, 10)}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export {PcDashboardPage};
