import {useState} from "react";
import {message} from "antd";
import {Dayjs} from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

import {httpRequest} from "~/fetch/common/http-request";

import {
    EmployeeListResponse,
    WeekWorkListType,
    WorkListRequest,
    WorkListResponse,
    WorkRegisterRequest,
} from "./work-plan-register.interface";

const useWorkPlanRegister = () => {
    const [employees, setEmployees] = useState<EmployeeListResponse[]>([]);
    const [employeeWorkList, setEmployeeWorkList] = useState<WorkListResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const submitEmployeeList = () => {
        return httpRequest<null, EmployeeListResponse[]>({
            method: "GET",
            url: "/human/list",
        })
            .submit()
            .then((res) => {
                setIsLoading(true);
                setEmployees(res.data);
            })
            .catch((e) => setError(e))
            .finally(() => setIsLoading(false));
    };

    const submitEmployeeWorkList = (config: {date: Dayjs; hrId: number}) => {
        return httpRequest<WorkListRequest, WorkListResponse[]>({
            method: "POST",
            url: "/dailyWork/hr/list",
            data: {
                startDate: config.date.startOf("isoWeek").format("YYYY-MM-DD"),
                endDate: config.date.endOf("isoWeek").format("YYYY-MM-DD"),
                hrId: config.hrId,
            },
        })
            .submit()
            .then((res) => {
                setIsLoading(true);
                setEmployeeWorkList(res.data);
            })
            .catch((e) => setError(e))
            .finally(() => setIsLoading(false));
    };

    const update = (week: WeekWorkListType[]) => {
        return httpRequest<WorkRegisterRequest[], any>({
            method: "POST",
            url: "/dailyWork/hr/update",
            data: week.map((data) => {
                return {
                    detail: data.detail,
                    hrId: data.hrId,
                    sectorYn: data.sectorYn,
                    trainerSectorId: data.trainerSectorId,
                    workDate: data.workDate,
                    workId: data.workId,
                    workTypeId: data.workTypeId === 0 ? null : data.workTypeId,
                };
            }),
        })
            .submit()
            .then((res) => {
                setIsLoading(true);
                if (res.data.result === "SUCCESS") {
                    message.success("저장되었습니다");
                }
            })
            .catch((e) => {
                message.error("에러가 발생하였습니다. 다시 시도해주세요");
                setError(e);
            })
            .finally(() => setIsLoading(false));
    };

    return {submitEmployeeList, employees, submitEmployeeWorkList, employeeWorkList, update, isLoading, error};
};

export {useWorkPlanRegister};
