import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {faCaretDown, faCaretUp, faClone} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";

import Button from "~/components/button/button";
import {
    hiddenMenuPathForManager,
    hiddenMenuPathForMember,
    hiddenMenuPathNameForMember,
    member,
    Menu,
    mobileMenu,
} from "~/components/sidebar/menus";
import {useUser} from "~/data/user";
import {usePlatform} from "~/utills/use-platform";

import type {ItemProps, MenuProps} from "./sidebar.interface";

import styles from "./sidebar.module.scss";
const cx = classNames.bind(styles);

export const Sidebar = () => {
    const {platform} = usePlatform();
    const navigate = useNavigate();

    return (
        <div className={cx("container")}>
            <div className={cx("menu-container")}>
                <MenuRenderer menu={platform === "desktop" ? member : mobileMenu} />
            </div>
            <div className={cx("help-center-container")}>
                <p>고객센터 : 070-4411-3170</p>
                <p>운영시간 : 10:00 ~ 17:00</p>
                <p>
                    문의사항 :{" "}
                    <span className={cx("inquiry-button")} onClick={() => navigate("/board/inquiry")}>
                        문의게시판 바로가기
                    </span>
                </p>
                {platform !== "mobile" && (
                    <p>
                        <span
                            className={cx("inquiry-button")}
                            onClick={() => window.open("https://old.lottemartfms.com/", "_blank")}
                        >
                            이전시스템 바로가기
                        </span>
                    </p>
                )}
            </div>
        </div>
    );
};

const MenuRenderer = (props: MenuProps) => {
    const {user} = useUser();
    return (
        <ul className={cx("list")}>
            {props.menu.map((item) => {
                if (user?.division === "MANAGER" && hiddenMenuPathForManager.indexOf(item.path as string) > -1)
                    return null;
                if (user?.division === "MEMBER" && hiddenMenuPathForMember.indexOf(item.path as string) > -1)
                    return null;
                if (user?.division === "MEMBER" && hiddenMenuPathNameForMember.indexOf(item.name as string) > -1)
                    return null;

                return <ItemRenderer item={item} key={item.name} />;
            })}
        </ul>
    );
};

const ItemRenderer = ({item}: ItemProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {pathname} = location;
    const [isOpened, setIsOpened] = useState<boolean>(!item?.isToggle);
    const {t} = useTranslation();

    const onToggle = () => {
        setIsOpened(!isOpened);
    };

    const handleMenuClick = (item: Menu) => {
        if (item.isToggle) onToggle();
        else if (item?.path) navigate(item.path);
    };

    return (
        <li key={item.name} className={cx("item")}>
            <a
                className={cx("name", {"current-page": item.path === pathname})}
                href="javascript:void(0)"
                onClick={() => handleMenuClick(item)}
            >
                {item?.icon && <FontAwesomeIcon icon={item.icon} className={cx("icon")} />}
                <span>{t(item.name) || item.name}</span>
                {item?.isToggle && (
                    <FontAwesomeIcon icon={isOpened ? faCaretUp : faCaretDown} className={cx("toggle-icon")} />
                )}
            </a>
            {item?.children && isOpened && <MenuRenderer key={item.name} menu={item.children} />}
        </li>
    );
};
