import {useEffect, useState} from "react";
import {App, DatePicker, Input, Select, Table} from "antd";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import Swal from "sweetalert2";

import Button from "~/components/button/button";
import {CnSelect} from "~/components/cn-select/cn-select";
import {CenterModal} from "~/components/modal/center-modal";
import {useWorkPlanRegister} from "~/data/work-plan/register/use-work-plan-register";
import {WeekWorkListType} from "~/data/work-plan/register/work-plan-register.interface";
import {useCommonWorkPlan} from "~/data/work-plan/use-common-work-plan";

import {WorkPlanRegisterModalProps} from "./work-plan-register.modal.interface";

import styles from "./work-plan-register.modal.module.scss";

import "dayjs/locale/ko";

dayjs.locale("ko");

const cx = classNames.bind(styles);

const WorkPlanRegisterModal = ({close, isShow, refresh}: WorkPlanRegisterModalProps) => {
    const [date, setDate] = useState<Dayjs>(dayjs());
    const [weekRange, setWeekRange] = useState("");
    const [employee, setEmployee] = useState(null);
    const [week, setWeek] = useState<WeekWorkListType[]>([]);

    const {submitEmployeeList, employees, submitEmployeeWorkList, employeeWorkList, update} = useWorkPlanRegister();
    const {submitList, workList} = useCommonWorkPlan();

    useEffect(() => {
        submitEmployeeList();
        submitList();
        if (date) {
            setDate(date);
            // 선택된 날짜의 월요일과 일요일을 계산
            const startOfWeek = date.startOf("isoWeek").format("YYYY-MM-DD");
            const endOfWeek = date.endOf("isoWeek").format("YYYY-MM-DD");

            // 주간 범위를 저장
            setWeekRange(`${startOfWeek} ~ ${endOfWeek}`);
        } else {
            setWeekRange("");
        }
    }, []);

    useEffect(() => {
        const daysOfWeek = [];
        for (let i = 0; i < 7; i++) {
            daysOfWeek.push(date.startOf("isoWeek").add(i, "day"));
        }
        const tempWeek = daysOfWeek.map((data, index) => ({
            dow: data,
            workTypeName: null,
            detail: null,
            hrId: employee ?? 0,
            workDate: data.format("YYYY-MM-DD"),
            workId: null,
            workTypeId: 0,
            trainerSectorId: 0,
            sectorYn: false,
        }));
        const mergedArr = tempWeek.map((item1) => {
            const matchedItem = employeeWorkList.find((item2) => item1.dow.format("YYYY-MM-DD") === item2.workDate);
            if (matchedItem) {
                return {...item1, ...matchedItem};
            }
            return item1;
        });
        setWeek(mergedArr);
    }, [date, employeeWorkList]);

    useEffect(() => {
        if (employee) submitEmployeeWorkList({date, hrId: employee});
    }, [employee, date]);

    const handleWeekChange = (date: Dayjs) => {
        Swal.fire({
            text: "날짜를 변경하면 입력하신 내용이 초기화됩니다. 변경하시겠습니까?",
            showCancelButton: true,
            cancelButtonText: "취소",
            confirmButtonText: "확인",
        }).then((info) => {
            if (info.isConfirmed) {
                if (date) {
                    setDate(date);
                    // 선택된 날짜의 월요일과 일요일을 계산
                    const startOfWeek = date.startOf("isoWeek").format("YYYY-MM-DD");
                    const endOfWeek = date.endOf("isoWeek").format("YYYY-MM-DD");

                    // 주간 범위를 저장
                    setWeekRange(`${startOfWeek} ~ ${endOfWeek}`);
                } else {
                    setWeekRange("");
                }
            }
        });
    };

    // 업무
    const handleWorkChange = (e: any, record: any) => {
        setWeek((prev) => {
            const updateArray = prev.map((item) => {
                if (item.workDate === record.dow.format("YYYY-MM-DD")) {
                    return {...item, workTypeId: e};
                }
                return item;
            });
            return updateArray;
        });
    };
    // 상세
    const handleDetailChange = (e: any, record: any) => {
        setWeek((prev) => {
            const updateArray = prev.map((item) => {
                if (item.workDate === record.dow.format("YYYY-MM-DD")) {
                    return {...item, detail: e};
                }
                return item;
            });
            return updateArray;
        });
    };

    return (
        <CenterModal
            isShow={isShow}
            close={close}
            closeIcon={false}
            title={"일일 업무계획 등록"}
            width={1200}
            extraButtons={[
                <Button
                    label="저장하기"
                    key={"confirm"}
                    onClick={() => {
                        if (employee) {
                            Swal.fire({
                                text: "저장하시겠습니까?",
                                showCancelButton: true,
                                confirmButtonText: "확인",
                                cancelButtonText: "취소",
                            })
                                .then(async (info) => {
                                    if (info.isConfirmed) await update(week);
                                })
                                .finally(() => {
                                    close();
                                    setTimeout(refresh, 500);
                                });
                        } else {
                            Swal.fire({
                                text: "대상자를 선택해주세요",
                                confirmButtonText: "확인",
                            });
                        }
                    }}
                />,
            ]}
            maskClosable={false}
        >
            <App>
                <div className={cx("options-container")}>
                    <div className={cx("container")}>
                        <label className={cx("label")}>기간</label>
                        <DatePicker
                            value={date}
                            onChange={handleWeekChange}
                            format={weekRange}
                            style={{width: "250px"}}
                            allowClear={false}
                        />
                    </div>
                    <CnSelect
                        label="대상자"
                        options={employees.map((data) => ({
                            label: data.name,
                            value: data.hrId,
                        }))}
                        value={employee}
                        onChange={(e) => setEmployee(e)}
                        placeholder={"대상자를 선택하세요"}
                        status={employee ? "" : "error"}
                    />
                </div>
                <p className={cx("table-label")}>일일 업무계획</p>
                <Table
                    pagination={false}
                    className={cx("table")}
                    bordered
                    rowKey={"dow"}
                    dataSource={week}
                    columns={[
                        {
                            title: "구분",
                            dataIndex: "dow",
                            key: "dow",
                            width: "15%",
                            render: (value, record) => <div>{value.format("dd (YYYY-MM-DD)")}</div>,
                            onCell: (record) => ({
                                style: {backgroundColor: "#efefef"},
                            }),
                        },
                        {
                            title: "업무",
                            dataIndex: "workTypeId",
                            key: "workTypeId",
                            width: "20%",
                            render: (value, record, index) => (
                                <Select
                                    className={cx("selects")}
                                    value={value}
                                    onChange={(e) => handleWorkChange(e, record)}
                                    disabled={!employee}
                                    options={[
                                        {
                                            label: "일정없음",
                                            value: 0,
                                        },
                                        ...workList.map((data) => ({
                                            label: data.name,
                                            value: data.workTypeId,
                                        })),
                                    ]}
                                />
                            ),
                        },
                        {
                            title: "상세",
                            dataIndex: "detail",
                            key: "detail",
                            render: (value, record, index) => (
                                <Input
                                    className={cx("inputs")}
                                    value={value}
                                    onChange={(e) => handleDetailChange(e.target.value, record)}
                                    placeholder="상세내용을 입력하세요"
                                    disabled={!employee || record.workTypeId === 0}
                                />
                            ),
                        },
                    ]}
                />
            </App>
        </CenterModal>
    );
};

export {WorkPlanRegisterModal};
