import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {faRightLeft, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, DatePicker, message, Steps, Tag} from "antd";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";
import Swal from "sweetalert2";

import {PageTitle} from "~/components/page-title";
import {CheckList} from "~/data/check/form/use-checklist-form.interface";
import {LegalForm, LegalFormDetail} from "~/data/check/form/use-legal-form.interface";
import {User} from "~/data/shop/use-department.interface";
import {Region} from "~/data/shop/use-region.interface";
import {httpRequest} from "~/fetch/common/http-request";
import {ChecklistFormSelect} from "~/pages/check/instruction/checklist/checklist-form-select";
import {ChecklistFormValue} from "~/pages/check/instruction/checklist/checklist-form-select.interface";
import {RegionSelect} from "~/pages/check/instruction/region-select";

import {InstructionParam} from "./instruction.interface";
import {LegalFormSelect} from "./legal/legal-form-select";
import type {LegalFormValue} from "./legal/legal-form-select.interface";

import styles from "./instruction.module.scss";

const cx = classNames.bind(styles);

const {TimePicker} = DatePicker;

const InstructionPage = () => {
    const [current, setCurrent] = useState(0);
    const [value, setValue] = useState<{form: LegalForm; detail: LegalFormDetail} | CheckList | null>(null);
    const [region, setRegion] = useState<Region | null>(null);
    const [member, setMember] = useState<User | null>(null);
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([dayjs(new Date()), dayjs(new Date()).add(1, "M")]);
    const [isChecked, setIsChecked] = useState<boolean>(true);
    const [selectedTags, setSelectedTags] = useState<string[]>([""]);
    const [timeValue, setTimeValue] = useState<Dayjs | null>(null);
    const [notiTimes, setNotiTimes] = useState<Array<string>>([]);
    const [days, setDays] = useState<Array<number>>([]);
    const [selectType, setSelectType] = useState<"DAY" | "DATE">("DAY");

    const {type} = useParams<InstructionParam>();
    const {t, i18n} = useTranslation();

    const title = useMemo(() => {
        if (type === "legal") return t("page.inspection.legal-inspection");
        else if (type === "checklist") return t("page.inspection.checklist");
        else if (type === "normal") return t("page.inspection.additional-inspection");
        else if (type === "extra") return t("page.inspection.general-inspection");
    }, [type, i18n.language]);

    const tagData = useMemo(() => ["일", "월", "화", "수", "목", "금", "토"], []);
    const dateData = useMemo(
        () => [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
            30, 31,
        ],
        [],
    );

    const tagValue = useMemo(() => {
        let value = 0;
        if (selectedTags.indexOf("일") > -1) value += Math.pow(2, 6);
        if (selectedTags.indexOf("월") > -1) value += Math.pow(2, 5);
        if (selectedTags.indexOf("화") > -1) value += Math.pow(2, 4);
        if (selectedTags.indexOf("수") > -1) value += Math.pow(2, 3);
        if (selectedTags.indexOf("목") > -1) value += Math.pow(2, 2);
        if (selectedTags.indexOf("금") > -1) value += Math.pow(2, 1);
        if (selectedTags.indexOf("토") > -1) value += Math.pow(2, 0);
        return value;
    }, [selectedTags]);

    const dateValue = useMemo(
        () =>
            days
                .map((day): number => Math.pow(2, 31 - day))
                .reduce((acc: number, cur) => {
                    return acc + cur;
                }, 0),
        [days],
    );

    useEffect(() => {
        message.info(steps[current].title);
    }, [current]);

    useEffect(() => {
        setValue(null);
        setDateRange([dayjs(new Date()), dayjs(new Date()).add(1, "M")]);
        setSelectedTags([]);
        setIsChecked(true);
        setMember(null);
        setRegion(null);
        setTimeValue(null);
        setSelectType("DAY");
        setSelectedTags([]);
        setDays([]);
        setNotiTimes([]);
        setCurrent(0);
    }, [type]);

    useEffect(() => {
        setSelectedTags([]);
        setDays([]);
    }, [selectType]);

    const steps = [
        {
            title: t("page.inspection.select-form"),
        },
        {
            title: t("page.inspection.select-region"),
        },
        {
            title: t("page.inspection.select-period"),
        },
    ];

    if (type === "legal" || type === "checklist") {
        steps.pop();
    }

    const next = () =>
        setCurrent((prevCurrent) => {
            if (prevCurrent === 0 && formName === t("page.inspection.form-name")) {
                message.warning("양식을 선택해주세요!");
                return prevCurrent;
            }
            if (prevCurrent === 1 && !region?.regionName) {
                message.warning("점검위치를 선택해주세요!");
                return prevCurrent;
            }
            return prevCurrent + 1;
        });
    const prev = () =>
        setCurrent((prevCurrent) => {
            return prevCurrent - 1;
        });

    const handleChange = (tag: string, checked: boolean) => {
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
    };
    const handleChangeDays = (tag: number, checked: boolean) => {
        const nextSelectedTags = checked ? [...days, tag] : days.filter((t) => t !== tag);
        setDays(nextSelectedTags);
    };
    const handleAllSelect = () => {
        if (selectType === "DAY") {
            setSelectedTags((prev) => (prev.length ? [] : tagData));
        } else {
            setDays((prev) => (prev.length ? [] : dateData));
        }
    };
    const formName = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        if (value?.form) return value?.form.reportNm;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        else if (value?.checkFormName) return value?.checkFormName;
        else return t("page.inspection.form-name");
    }, [value, i18n.language]);

    const navigate = useNavigate();

    const handleSubmit = () => {
        if (formName === t("page.inspection.form-name")) {
            message.warning("양식을 선택해주세요!");
            return false;
        }
        if (!region?.regionName) {
            message.warning("점검위치를 선택해주세요!");
            return false;
        }
        if (type === "extra" || type === "normal") {
            if (tagValue + dateValue < 1) {
                message.warning("요일 혹은 날짜를 선택해주세요!");
                return false;
            }
            if (notiTimes.length < 1) {
                message.warning("점검시간을 선택해주세요!");
                return false;
            }
        }

        Swal.fire({
            title: "점검을 지시하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "지시하기",
            cancelButtonText: "취소하기",
        }).then((result) => {
            if (result.isConfirmed) {
                if (type === "legal" || type == "checklist") {
                    httpRequest<any, any>({
                        method: "POST",
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        url: `/reportCheck/${value!.form.reportInfoId}/checks`,
                        data: {
                            startDate: dateRange[0].format("YYYY-MM-DD"),
                            endDate: isChecked ? "2100-01-01" : dateRange[1].format("YYYY-MM-DD"),
                            reportCheckDTOs: [
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                ...value!.detail.list.map((form) => {
                                    return {
                                        category: type === "legal" ? "LEGAL" : "CHECK",
                                        reportCheckId: form.reportCheckId,
                                        reportInfoId: form.reportInfoId,
                                        companyId: member?.companyId,
                                        buildingId: region?.buildingId,
                                        checkHistoryNm: form.checkHistoryNm,
                                        patternType: form.patternType,
                                        value: form.value,
                                    };
                                }),
                            ],
                            regionId: region?.regionId,
                            employeeId: member?.userId,
                            regionName: region?.regionName,
                            employeeName: member?.name,
                        },
                    })
                        .submit()
                        .then((res) => {
                            Swal.fire({
                                title: "점검 지시가 완료되었습니다.",
                                confirmButtonText: "확인",
                            }).then(() => {
                                navigate(`/check/${type}/history`, {replace: true});
                            });
                        });
                } else {
                    httpRequest<any, any>({
                        method: "POST",
                        url: `/buildings/${region?.buildingId}/checks`,
                        data: {
                            patternType: tagValue > 0 ? "0" : "1",
                            value: tagValue + dateValue,
                            startDate: dateRange[0].format("YYYY-MM-DD"),
                            endDate: isChecked ? "2100-01-01" : dateRange[1].format("YYYY-MM-DD"),
                            notiTimeList: notiTimes,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            checkFormId: value.checkFormId,
                            regionId: region?.regionId,
                            employeeId: member?.userId,
                        },
                    })
                        .submit()
                        .then((res) => {
                            Swal.fire({
                                title: "점검 지시가 완료되었습니다.",
                                confirmButtonText: "확인",
                            }).then(() => {
                                navigate(`/check/${type}/history`, {replace: true});
                            });
                        });
                }
            }
        });
    };

    const addNotiTime = () => {
        if (timeValue)
            setNotiTimes((notiTimes) => {
                notiTimes.push(timeValue.format("HH:mm"));
                return notiTimes.filter((v: string, i: number) => notiTimes.indexOf(v) === i);
            });
    };

    return (
        <div>
            <PageTitle>{title + " " + t("page.inspection.give-instruction")}</PageTitle>
            <div className={cx("button-container")}>
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                        {t("common.next")}
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={() => handleSubmit()}>
                        {t("page.inspection.give-instruction")}
                    </Button>
                )}
                {current > 0 && (
                    <Button style={{margin: "0 8px"}} onClick={() => prev()}>
                        {t("common.prev")}
                    </Button>
                )}
            </div>
            <div className={cx("steps-container")}>
                <Steps current={current} items={steps} />
            </div>
            <div className={cx("steps-description-container")}>
                <span>{formName}</span>
                <span>{region?.regionName ? region?.regionName : t("page.inspection.inspection-location")}</span>
                {/* <span>{member?.name ? member?.name : "점검자"}</span> */}
                {(type === "extra" || type === "normal") && (
                    <span>
                        {dateRange[0].format("YYYY-MM-DD")} ~
                        {isChecked ? "계약만료일" : dateRange[1].format("YYYY-MM-DD")}
                    </span>
                )}
            </div>
            <div className={cx("contents-container")}>
                {current === 0 && (type === "legal" || type === "checklist") && (
                    <LegalFormSelect
                        key={type}
                        type={type}
                        defaultValue={value as LegalFormValue}
                        onChange={(value) => setValue(value)}
                    />
                )}
                {current === 0 && (type === "extra" || type === "normal") && (
                    <ChecklistFormSelect
                        key={type}
                        type={type}
                        defaultValue={value as ChecklistFormValue}
                        onChange={(value) => setValue(value)}
                    />
                )}
                {current === 1 && (
                    <RegionSelect
                        onChange={(region) => setRegion(region)}
                        onChangeMember={(member) => setMember(member)}
                    />
                )}
                {current === 2 && (
                    <Fragment>
                        {/* {

                        }
                        <div className={cx("date")}>
                            <CnRangePicker label={"기간"} value={dateRange} onChange={setDateRange} />
                            <Checkbox
                                value={isChecked}
                                onChange={(e) => {
                                    setIsChecked(e.target.checked);
                                }}
                            >
                                ~ 계약만료일
                            </Checkbox>
                        </div> */}
                        {(type === "extra" || type === "normal") && (
                            <Fragment>
                                <div className={cx("tag-container")}>
                                    <p className={cx("title")}>
                                        {selectType === "DAY" ? "요일선택" : "날짜선택"}
                                        <Button
                                            size="small"
                                            type="text"
                                            icon={<FontAwesomeIcon icon={faRightLeft} />}
                                            onClick={() => {
                                                setSelectType(selectType === "DAY" ? "DATE" : "DAY");
                                            }}
                                        >
                                            {selectType === "DAY" ? "날짜선택" : "요일선택"}
                                        </Button>
                                        <Button style={{marginLeft: "5px"}} onClick={handleAllSelect}>
                                            매일
                                        </Button>
                                    </p>
                                    <div className={cx("tag")}>
                                        {selectType === "DAY" ? (
                                            <Fragment>
                                                {tagData.map<React.ReactNode>((tag) => (
                                                    <Tag.CheckableTag
                                                        key={tag}
                                                        checked={selectedTags.includes(tag)}
                                                        onChange={(checked) => handleChange(tag, checked)}
                                                        className={cx("tags")}
                                                    >
                                                        {tag}
                                                    </Tag.CheckableTag>
                                                ))}
                                            </Fragment>
                                        ) : (
                                            <div className={cx("date-tag-container")}>
                                                {dateData
                                                    .reduce<React.ReactNode[][]>((acc, dateNum, index) => {
                                                        if (index % 7 === 0) {
                                                            acc.push([]);
                                                        }
                                                        acc[acc.length - 1].push(
                                                            <Tag.CheckableTag
                                                                key={dateNum}
                                                                checked={days.includes(dateNum)}
                                                                onChange={(checked) =>
                                                                    handleChangeDays(dateNum, checked)
                                                                }
                                                                className={cx("date-tags")}
                                                            >
                                                                {dateNum}일
                                                            </Tag.CheckableTag>,
                                                        );
                                                        return acc;
                                                    }, [])
                                                    .map((group, index) => (
                                                        <div key={index} className={cx("date-group")}>
                                                            {group}
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={cx("time-container")}>
                                    <p className={cx("title")}>시간선택</p>
                                    <div className={cx("time")}>
                                        <TimePicker
                                            use12Hours={false}
                                            showHour
                                            showMinute
                                            minuteStep={10}
                                            onChange={(date) => setTimeValue(date)}
                                            value={timeValue}
                                        />
                                        <Button onClick={addNotiTime}>추가하기</Button>
                                    </div>
                                    <ul className={cx("notiTimes")}>
                                        {notiTimes.map((notiTime) => (
                                            <li key={notiTime}>
                                                {notiTime}{" "}
                                                <Button
                                                    size="small"
                                                    type="text"
                                                    icon={
                                                        <FontAwesomeIcon
                                                            icon={faXmark}
                                                            onClick={() => {
                                                                setNotiTimes((notiTimes) => {
                                                                    return notiTimes.filter(
                                                                        (v: string) => v !== notiTime,
                                                                    );
                                                                });
                                                            }}
                                                        />
                                                    }
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </div>
        </div>
    );
};

export {InstructionPage};
