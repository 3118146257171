import React, {Fragment, useEffect, useState} from "react";
import {ResponsiveSankey} from "@nivo/sankey";
import {Empty} from "antd";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";

import Button from "~/components/button";
import {CnRangePicker} from "~/components/cn-range-picker";
import {CnSelect} from "~/components/cn-select";
import {CnTable} from "~/components/cn-table/cn-table";
import {FilterArea} from "~/components/filter-area";
import {PageTitle} from "~/components/page-title";
import {useCheckElevatorList} from "~/data/check/elevator/use-check-elevator";
import {CheckElevatorResponse, CheckElevatorStatus} from "~/data/check/elevator/use-check-elevator.interface";
import {useUser} from "~/data/user";
import {useShop} from "~/hooks/use-shop/use-shop";
import {ElevatorCheckStatus} from "~/pages/check/elevator/elevator-check.interface";
import {ElevatorCheckRequestModal} from "~/pages/check/elevator/request/elevator-check-request.modal";
import {ElevatorCheckRequestEditModal} from "~/pages/check/elevator/request/elevator-check-request-edit.modal";

import styles from "./elevator-check.page.module.scss";

const cx = classNames.bind(styles);

const ElevatorCheckPage = () => {
    const perPage = 50;
    const [page, setPage] = useState(1);
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([dayjs(new Date()).add(-6, "d"), dayjs(new Date())]);
    const [buildingId, setBuildingId] = useState<number>(0);
    const [status, setStatus] = useState<ElevatorCheckStatus | null>(null);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [isShowDetailModal, setIsShowDetailModal] = useState<false | number>(false);

    const {role, buildingId: buildingIdFromUser} = useUser();

    const {loading, summary, list, statusValueByKey, totalCount, refresh, downloadReport} = useCheckElevatorList({
        ignore: role === undefined,
        perPage,
        page,
        buildingId: buildingId === 0 ? undefined : buildingId,
        checkStatus: status as ElevatorCheckStatus,
        startDate: dateRange && dateRange[0].format("YYYY-MM-DD"),
        endDate: dateRange && dateRange[1].format("YYYY-MM-DD"),
    });

    useEffect(() => {
        if (role === "MANAGER" || role === "EMPLOYEE") setBuildingId(buildingIdFromUser!);
    }, [buildingIdFromUser]);

    const handleResetFilter = () => {
        setPage(1);
        setStatus(null);
        setDateRange([dayjs(new Date()).add(-6, "d"), dayjs(new Date())]);
        if (role === "MANAGER" || role === "EMPLOYEE") setBuildingId(buildingIdFromUser!);
        else {
            shopReset();
        }
    };

    const handleSubmit = () => {
        setPage(1);
        refresh();

        // submit();
    };

    const handleNewRequest = () => {
        setIsShowModal(true);
    };

    const {shop, reset: shopReset, ShopSelect} = useShop();

    useEffect(() => {
        setBuildingId(shop);
    }, [shop]);

    return (
        <div>
            {isShowModal && <ElevatorCheckRequestModal close={() => setIsShowModal(false)} refresh={refresh} />}
            {isShowDetailModal && (
                <ElevatorCheckRequestEditModal
                    checkId={isShowDetailModal}
                    close={() => setIsShowDetailModal(false)}
                    refresh={refresh}
                />
            )}
            <PageTitle>승강기 점검 목록</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <CnRangePicker
                        label={"기간"}
                        value={dateRange}
                        onChange={(value) => {
                            if (!value) setDateRange([dayjs(new Date()).add(-6, "d"), dayjs(new Date())]);
                            else setDateRange(value);
                        }}
                    />
                    {role === "MEMBER" && (
                        <Fragment>
                            <ShopSelect />
                        </Fragment>
                    )}
                    <CnSelect
                        label={"점검상태"}
                        showSearch
                        placeholder="점검상태"
                        onChange={(value) => setStatus(value)}
                        value={status}
                        options={[
                            {
                                value: null,
                                label: "전체",
                            },
                            {
                                value: "REQUEST",
                                label: "접수완료",
                            },
                            {
                                value: "ING",
                                label: "점검중",
                            },
                            {
                                value: "COMPLETED",
                                label: "조치완료",
                            },
                        ]}
                    />
                    <div className={cx("button-container")}>
                        <Button onClick={handleSubmit} label={"조회"} color={"primary"} />
                        <Button onClick={handleResetFilter} label={"검색조건 초기화"} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("chart-container")}>
                <div className={cx("sankey-container")}>
                    {summary.ING === 0 && summary.penalty_below === 0 && summary.penalty_above === 0 ? (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    ) : (
                        <ResponsiveSankey
                            data={{
                                nodes: [
                                    {
                                        id: "점검요청됨",
                                    },
                                    {
                                        id: "점검중",
                                    },
                                    {
                                        id: "1시간이내",
                                    },
                                    {
                                        id: "1시간초과",
                                    },
                                    {
                                        id: "점검완료",
                                    },
                                ],
                                links: [
                                    {
                                        source: "점검요청됨",
                                        target: "점검중",
                                        value: summary.ING ?? 1,
                                    },
                                    {
                                        source: "점검중",
                                        target: "1시간이내",
                                        value: summary.penalty_below ?? 0,
                                    },
                                    {
                                        source: "점검중",
                                        target: "1시간초과",
                                        value: summary.penalty_above ?? 0,
                                    },
                                    {
                                        source: "1시간이내",
                                        target: "점검완료",
                                        value: summary.penalty_below ?? 0,
                                    },
                                    {
                                        source: "1시간초과",
                                        target: "점검완료",
                                        value: summary.penalty_above ?? 0,
                                    },
                                ],
                            }}
                            margin={{right: 60, left: 80, top: 20, bottom: 20}}
                            align="justify"
                            colors={{scheme: "category10"}}
                            nodeOpacity={1}
                            nodeHoverOthersOpacity={0.35}
                            nodeThickness={18}
                            nodeSpacing={24}
                            nodeBorderWidth={0}
                            nodeBorderColor={{
                                from: "color",
                                modifiers: [["darker", 0.8]],
                            }}
                            nodeBorderRadius={3}
                            linkOpacity={0.5}
                            linkHoverOthersOpacity={0.1}
                            linkContract={3}
                            enableLinkGradient={true}
                            labelPosition="outside"
                            labelOrientation="horizontal"
                            labelPadding={10}
                            labelTextColor={{
                                from: "color",
                                modifiers: [["darker", 1]],
                            }}
                            // legends={[
                            //     {
                            //         anchor: "bottom-right",
                            //         direction: "column",
                            //         translateX: 130,
                            //         itemWidth: 100,
                            //         itemHeight: 14,
                            //         itemDirection: "right-to-left",
                            //         itemsSpacing: 2,
                            //         itemTextColor: "#999",
                            //         symbolSize: 14,
                            //         effects: [
                            //             {
                            //                 on: "hover",
                            //                 style: {
                            //                     itemTextColor: "#000",
                            //                 },
                            //             },
                            //         ],
                            //     },
                            // ]}
                        />
                    )}
                </div>
            </div>
            <div className={cx("table-container")}>
                {role !== "MEMBER" && (
                    <div className={cx("button-container")}>
                        <Button onClick={handleNewRequest} label={"승강기 점검 요청"} />
                    </div>
                )}
                <CnTable
                    totalCount={totalCount}
                    onPageChange={(page) => setPage(page)}
                    perPage={perPage}
                    page={page}
                    dataSource={list?.map((row: any) => {
                        row["key"] = row.liftId;
                        return row;
                    })}
                    pagination={false}
                    loading={loading}
                    onRow={(row) => ({onClick: () => setIsShowDetailModal(row.liftId)})}
                    columns={[
                        {
                            title: "No",
                            key: "index",
                            render: (_, __, index) => Number((page - 1) * perPage + index + 1).toLocaleString(),
                            align: "center",
                        },
                        {
                            title: "발생일시",
                            dataIndex: "breakDate",
                            key: "breakDate",
                            align: "center",
                        },
                        {
                            title: "도착시간",
                            dataIndex: "arriveTime",
                            key: "arriveTime",
                            align: "center",
                        },
                        {
                            title: "권역",
                            dataIndex: "trainerSectorName",
                            key: "trainerSectorName",
                            align: "center",
                        },
                        {
                            title: "지점",
                            dataIndex: "buildingName",
                            key: "buildingName",
                            align: "center",
                        },
                        {
                            title: "구역",
                            dataIndex: "breakLocation",
                            key: "breakLocation",
                            align: "center",
                            render: (_, row) => row.breakLocation + " " + row.breakUnit,
                        },
                        {
                            title: "점검자",
                            dataIndex: "employeeName",
                            key: "employeeName",
                            align: "center",
                        },
                        {
                            title: "패널티",
                            dataIndex: "penalty",
                            key: "penalty",
                            align: "center",
                        },
                        {
                            title: "점검상태",
                            dataIndex: "checkStatus",
                            key: "checkStatus",
                            align: "center",
                            render: (data: CheckElevatorStatus) => statusValueByKey[data],
                        },
                        {
                            title: "내려받기",
                            dataIndex: "liftId",
                            key: "liftId",
                            align: "center",
                            width: "8%",
                            render: (data, row: CheckElevatorResponse) =>
                                row.checkStatus === "COMPLETED" && (
                                    <Button
                                        label={"내려받기"}
                                        color={"primary"}
                                        size="small"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            downloadReport(row.liftId);
                                        }}
                                    />
                                ),
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {ElevatorCheckPage};
