import {useEffect, useState} from "react";
import {faCircleChevronLeft, faCircleChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";

import Button from "~/components/button/button";
import {PageTitle} from "~/components/page-title/page-title";
import {useWorkPlanList} from "~/data/work-plan/use-work-plan";

import {WorkPlanDetailModal} from "./modal/work-plan-detail.modal";
import {WorkPlanRegisterModal} from "./modal/work-plan-register.modal";
import {WorkPlanWorkModal} from "./modal/work-plan-work.modal";
import {WorkPlanList} from "./work-plan.list";

import styles from "./work-plan.page.module.scss";

const cx = classNames.bind(styles);

const WorkPlanPage = () => {
    const [date, setDate] = useState<{startDate: Dayjs; endDate: Dayjs}>({
        startDate: dayjs().startOf("isoWeek"),
        endDate: dayjs().endOf("isoWeek"),
    });
    const [weekly, setWeekly] = useState<JSX.Element[]>([]);
    const [isTeamModal, setIsTeamModal] = useState<boolean>(false);
    const [registerModal, setRegisterModal] = useState<boolean>(false);
    const [detailModal, setDetailModal] = useState<boolean>(false);
    const [detailDate, setDetailDate] = useState<Dayjs>(dayjs());

    const weekDays = ["월", "화", "수", "목", "금", "토", "일"];

    const {submitWeek, week} = useWorkPlanList(date.startDate.format("YYYY-MM-DD"));

    useEffect(() => {
        const daysOfWeek = [];
        for (let i = 0; i < 7; i++) {
            daysOfWeek.push(date.startDate.add(i, "day"));
        }
        setWeekly(
            daysOfWeek.map((data, index) => (
                <div
                    key={index}
                    className={cx("weekly", {
                        sat: index === 5, // 토요일 스타일 적용
                        sun: index === 6, // 일요일 스타일 적용
                    })}
                    onClick={() => {
                        setDetailDate(data);
                        setDetailModal(true);
                    }}
                >
                    <p className={cx("day")}>
                        {data.format("D")}
                        {data.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD") && (
                            <span className={cx("today")}>(오늘)</span>
                        )}
                    </p>{" "}
                    {/* 해당 날짜 */}
                    <p className={cx("dow")}>{weekDays[index]}</p> {/* 요일 */}
                </div>
            )),
        );
        submitWeek();
    }, [date]);

    // 오늘로 이동
    const handleToday = () => {
        setDate({
            startDate: dayjs().startOf("isoWeek"),
            endDate: dayjs().endOf("isoWeek"),
        });
    };

    // 저번 주로 이동
    const handlePreviousWeek = () => {
        setDate({
            startDate: date.startDate.subtract(1, "week").startOf("isoWeek"),
            endDate: date.endDate.subtract(1, "week").endOf("isoWeek"),
        });
    };

    // 다음 주로 이동
    const handleNextWeek = () => {
        setDate({
            startDate: date.startDate.add(1, "week").startOf("isoWeek"),
            endDate: date.endDate.add(1, "week").endOf("isoWeek"),
        });
    };

    return (
        <>
            {detailModal && (
                <WorkPlanDetailModal isShow={detailModal} close={() => setDetailModal(false)} date={detailDate} />
            )}
            {isTeamModal && (
                <WorkPlanWorkModal isShow={isTeamModal} refresh={submitWeek} close={() => setIsTeamModal(false)} />
            )}
            {registerModal && (
                <WorkPlanRegisterModal
                    refresh={submitWeek}
                    isShow={registerModal}
                    close={() => setRegisterModal(false)}
                />
            )}
            <div>
                <PageTitle>
                    <div className={cx("title-container")}>
                        <span className={cx("title")}>일일 업무 계획</span>
                        <Button
                            label="설정"
                            onClick={() => {
                                setIsTeamModal(true);
                            }}
                        />
                        <Button
                            label="등록"
                            onClick={() => {
                                setRegisterModal(true);
                            }}
                        />
                    </div>
                </PageTitle>
                <div className={cx("date-container")}>
                    <div className={cx("date-picker")}>
                        <FontAwesomeIcon
                            icon={faCircleChevronLeft}
                            className={cx("icons")}
                            onClick={handlePreviousWeek}
                        />
                        {date.startDate.format("YYYY-MM-DD")} <span className={cx("tilde")}>~</span>{" "}
                        {date.endDate.format("YYYY-MM-DD")}
                        <FontAwesomeIcon icon={faCircleChevronRight} className={cx("icons")} onClick={handleNextWeek} />
                        <Button label="오늘" onClick={handleToday} color="primary" />
                    </div>
                    <div className={cx("weekly-container")}>{weekly}</div>
                </div>
                <div className={cx("content")}>
                    {week.map((data, index) => (
                        <WorkPlanList
                            {...data}
                            openModal={() => {
                                setDetailDate(dayjs(data.day));
                                setDetailModal(true);
                            }}
                            key={index}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export {WorkPlanPage};
