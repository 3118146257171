import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {DatePicker, Input} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import Button from "~/components/button/button";
import {CnSelect} from "~/components/cn-select/cn-select";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {FilterArea} from "~/components/filter-area/filter-area";
import {FilterItem} from "~/components/filter-item/filter-item";
import {PageTitle} from "~/components/page-title/page-title";
import {useCostFilterTypeList} from "~/data/cost/common/use-cost-filter";
import {
    useCostFacilityMemberApproval,
    useCostFacilityMemberConfirm,
    useCostFacilityMemberFix,
} from "~/data/cost/facility-maintenance/modal/use-cost-facility-member";
import {
    useCostFacilityExcelDownload,
    useFacilityMaintenanceList,
} from "~/data/cost/facility-maintenance/use-facility-maintenance";
import {useShopCascader} from "~/hooks/use-shop-cascader/use-shop-cascader";
import {CostFacilityMaintenanceModal} from "~/pages/cost/facility-maintenance/modal/cost-facility-maintenance.modal";
import {useLocalStorage} from "~/utills/useLocalStorage";

import {CostFacilityInputType} from "./cost-facility-maintenance.interface";

import styles from "./cost-facility-maintenance.module.scss";
import tableStyles from "~/components/custom-label-table/custom-label-table.module.scss";
import filterStyles from "~/components/filter-item/filter-item.module.scss";

const cx = classNames.bind(styles);
const filterCx = classNames.bind(filterStyles);
const tableCx = classNames.bind(tableStyles);

const CostFacilityMaintenancePage = () => {
    const {typeId, trainerSectorId, dates} = Object.fromEntries(new URLSearchParams(useLocation().search));
    const {t} = useTranslation();
    const perPage = 50;
    const [page, setPage] = useState<number>(1);
    const [division, setDivision] = useState<"MEMBER" | "MANAGER" | "EMPLOYEE" | null>(null);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<React.Key[]>([]); // 선택된 행
    const [costFacilityId, setCostFacilityId] = useState<number>(0);
    const [buildingId, setBuildingId] = useState<number>(0);
    const [inputs, setInputs] = useState<CostFacilityInputType>({
        year: dates ? dayjs(dates.split(",")[0]) : dayjs(),
        month: dates ? dayjs(dates.split(",")[1]) : dayjs(),
        typeId: typeId ? typeId : "ALL",
        yearProcessId: "ALL",
        state: "ALL",
        startCost: 0,
        endCost: 999999999,
        yearConstructName: "",
    });

    const {setItem, getItem} = useLocalStorage();
    const {ShopCascader, selected, setSelected} = useShopCascader(
        140,
        trainerSectorId ? ["TRAINER", trainerSectorId, "0"] : undefined,
    );
    // 유형 리스트
    const [submitTypeList, options] = useCostFilterTypeList();
    // 테이블
    const [submitTableList, tableList, isTableListLoading] = useFacilityMaintenanceList({
        ...inputs,
        selected,
        page,
        perPage,
    });
    //엑셀 다운
    const [downloadExcel] = useCostFacilityExcelDownload({
        ...inputs,
        selected,
    });

    // 본사 확인
    const [checkMember] = useCostFacilityMemberConfirm({costFacilityId, selectedCostFacilityId: selectedRows});
    // 본사 승인
    const [approvalMember] = useCostFacilityMemberApproval({costFacilityId, selectedCostFacilityId: selectedRows});
    // 본사 확정
    const [fixMember] = useCostFacilityMemberFix({costFacilityId, fixedCost: 0, selectedCostFacilityId: selectedRows});

    // 계정 권한
    useEffect(() => {
        setDivision(JSON.parse(getItem("LM_USER")!).division);
    }, []);

    // 초기 셋팅
    useEffect(() => {
        submitTypeList();
    }, []);
    // 테이블 호출
    useEffect(() => {
        window.scrollTo(0, 0);
        submitTableList();
    }, [page]);

    useEffect(() => {
        submitTableList();
    }, [selected, inputs]);

    const handleReset = useCallback(() => {
        setInputs({
            year: dayjs(),
            month: dayjs(),
            typeId: "ALL",
            yearProcessId: "ALL",
            state: "ALL",
            startCost: 0,
            endCost: 999999999,
            yearConstructName: "",
        });
        setSelected(["ALL"]);
    }, []);

    const handleRowClick = (
        costFacilityId: number,
        buildingId: number,
        event: React.MouseEvent<HTMLTableRowElement>,
    ) => {
        const clickedElement = event.target as HTMLElement;
        const closestCheckboxCell = clickedElement.closest(".ant-table-selection-column");

        if (closestCheckboxCell) {
            return; // 체크박스 셀 클릭 시 모달 띄우지 않음
        } else {
            setCostFacilityId(costFacilityId);
            setBuildingId(buildingId);
            setIsShowModal(true);
        }
    };

    return (
        <div>
            {isShowModal && (
                <CostFacilityMaintenanceModal
                    costFacilityId={costFacilityId}
                    close={() => setIsShowModal(false)}
                    division={division}
                    submitTableList={submitTableList}
                    buildingId={buildingId}
                />
            )}
            <PageTitle>시설보수비 월간계획</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <FilterItem label={"연도"}>
                        <DatePicker
                            placeholder={"연도 선택"}
                            picker={"year"}
                            style={{width: "80px"}}
                            status={inputs.year === null ? "error" : ""}
                            value={inputs.year}
                            onChange={(e) => setInputs((prev) => ({...prev, year: e}))}
                            allowClear={false}
                        />
                    </FilterItem>
                    <FilterItem label={"월"}>
                        <DatePicker
                            placeholder={"전체"}
                            className={filterCx("filter-datepicker")}
                            popupClassName="no-header"
                            picker={"month"}
                            format={"MM"}
                            style={{width: "80px"}}
                            value={inputs.month}
                            onChange={(e) => setInputs((prev) => ({...prev, month: e}))}
                            cellRender={(date) => (
                                <div className="ant-picker-cell-inner">{dayjs(date).format("MM")}</div>
                            )}
                        />
                    </FilterItem>
                    <CnSelect
                        label={"유형"}
                        style={{width: 140}}
                        value={inputs.typeId}
                        onChange={(e) => setInputs((prev) => ({...prev, typeId: e}))}
                        options={[{label: "전체", value: "ALL"}, ...options.typeList]}
                    />
                    <FilterItem label={t("filter.department-trainer")}>
                        <ShopCascader />
                    </FilterItem>
                    <CnSelect
                        label={"공정"}
                        style={{width: 140}}
                        value={inputs.yearProcessId}
                        onChange={(e) => setInputs((prev) => ({...prev, yearProcessId: e}))}
                        options={[{label: "전체", value: "ALL"}, ...options.processList]}
                    />
                    <CnSelect
                        label={"상태"}
                        style={{width: 180}}
                        value={inputs.state}
                        onChange={(e) => setInputs((prev) => ({...prev, state: e}))}
                        options={[
                            {
                                label: "전체",
                                value: "ALL",
                            },
                            {
                                label: "제출전",
                                value: "Y_CONFIRM",
                            },
                            {
                                label: "월시행계획 제출",
                                value: "M_SUBMIT",
                            },
                            {
                                label: "검토중",
                                value: "M_APPROVAL",
                            },
                            {
                                label: "월시행계획 본사 확인",
                                value: "M_VERIFY_CONFIRM",
                            },
                            {
                                label: "월시행계획 본사 취소",
                                value: "M_VERIFY_CANCEL",
                            },
                            {
                                label: "확정",
                                value: "M_CONFIRM",
                            },
                        ]}
                    />

                    <FilterItem label={"공사건명"}>
                        <Input
                            style={{width: "180px"}}
                            value={inputs.yearConstructName}
                            onChange={(e) => setInputs((prev) => ({...prev, yearConstructName: e.target.value}))}
                        />
                    </FilterItem>
                    <div className={cx("button-container")}>
                        <Button
                            label={"조회"}
                            color={"primary"}
                            onClick={() => {
                                setPage(1);
                                if (page === 1) {
                                    submitTableList();
                                }
                            }}
                        />
                        <Button
                            label="엑셀 다운"
                            color={"primary"}
                            onClick={() => {
                                downloadExcel();
                            }}
                        />
                        <Button label={"검색조건 초기화"} onClick={handleReset} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("table-container")}>
                <CustomLabelTable
                    label={
                        <>
                            <span>
                                총액 : {tableList?.allRecordCost ? tableList.allRecordCost.toLocaleString() : 0} 원 /
                                검토중 : {tableList?.approvalCost.toLocaleString() ?? 0} 원 / 확정 :{" "}
                                {tableList?.confirmCost.toLocaleString() ?? 0} 원 / 기타 :{" "}
                                {tableList?.etcCost.toLocaleString() ?? 0} 원 / 총{" "}
                                {tableList?.allRecordCnt.toLocaleString() ?? 0} 건
                            </span>
                            <div className={tableCx("button-container")}>
                                {division === "MEMBER" ? (
                                    <>
                                        <span>{selectedRows.length} 건 선택됨</span>
                                        <Button
                                            label="확인"
                                            onClick={() => {
                                                checkMember();
                                                setTimeout(submitTableList, 500);
                                            }}
                                        />
                                        <Button
                                            label="승인"
                                            onClick={() => {
                                                approvalMember();
                                                setTimeout(submitTableList, 500);
                                            }}
                                        />
                                        <Button
                                            label="확정"
                                            onClick={() => {
                                                fixMember();
                                                setTimeout(submitTableList, 500);
                                            }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            label="등록"
                                            onClick={() => {
                                                setCostFacilityId(0);
                                                setIsShowModal(true);
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        </>
                    }
                    perPage={perPage}
                    page={page}
                    onRow={(record) => ({
                        onClick: (e) => handleRowClick(record.costFacilityId, record.buildingId, e),
                    })}
                    onPageChange={(page) => setPage(page)}
                    totalCount={tableList?.allRecordCnt ?? 0}
                    pagination={false}
                    dataSource={tableList?.resultList ?? []}
                    loading={isTableListLoading}
                    rowKey={"costFacilityId"}
                    rowSelection={
                        division === "MANAGER"
                            ? undefined
                            : {
                                  selectedRowKeys: selectedRows,
                                  onChange: (selectedRowKeys: React.Key[]) => {
                                      setSelectedRows(selectedRowKeys);
                                  },
                              }
                    }
                    columns={[
                        {
                            title: "No",
                            render: (_, __, index) => index + 1 + (page - 1) * perPage,
                            width: "4%",
                            align: "center",
                        },
                        {
                            title: "연도",
                            key: "year",
                            dataIndex: "year",
                            width: "5%",
                        },
                        {
                            title: "월",
                            key: "month",
                            dataIndex: "month",
                            width: "4%",
                        },
                        {
                            title: "부문",
                            key: "sectorName",
                            dataIndex: "sectorName",
                            width: "6%",
                        },
                        {
                            title: "지점",
                            key: "buildingName",
                            dataIndex: "buildingName",
                            width: "6%",
                        },
                        {
                            title: "유형",
                            key: "typeName",
                            dataIndex: "typeName",
                            width: "8%",
                        },
                        {
                            title: "선진행 여부",
                            key: "monthConstructGoYnDesc",
                            dataIndex: "monthConstructGoYnDesc",
                            width: "5%",
                        },
                        {
                            title: "공정",
                            key: "yearProcessName",
                            dataIndex: "yearProcessName",
                            width: "6%",
                        },
                        {
                            title: "공사건명",
                            key: "yearConstructName",
                            width: "18%",
                            dataIndex: "yearConstructName",
                            ellipsis: true,
                        },
                        {
                            title: "반영금액",
                            key: "monthConstructCost",
                            dataIndex: "monthConstructCost",
                            render: (value) => value.toLocaleString() ?? 0,
                            width: "7%",
                        },
                        {
                            title: "설비 연동",
                            key: "yearMachineLinkYnDesc",
                            dataIndex: "yearMachineLinkYnDesc",
                            width: "5%",
                        },
                        {
                            title: "설비",
                            key: "yearMachineName",
                            dataIndex: "yearMachineName",
                            width: "8%",
                            ellipsis: true,
                        },
                        // {
                        //     title: "등록일",
                        //     key: "createDate",
                        //     dataIndex: "createDate",
                        //     width: "7%",
                        // },
                        {
                            title: "상태",
                            key: "stateDesc",
                            dataIndex: "stateDesc",
                            width: "11%",
                        },
                        {
                            title: "확정 여부",
                            key: "finalConfirm",
                            dataIndex: "finalConfirm",
                            width: "5%",
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {CostFacilityMaintenancePage};
