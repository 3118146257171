import {useEffect, useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {
    InspectionRateListRequest,
    InspectionRateListResponse,
    StoreFormListType,
    TrainerSectorListType,
} from "./use-inspection-rate.interface";

const useInspectionRateList = (config: InspectionRateListRequest) => {
    const [response, setResponse] = useState<InspectionRateListResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isExcelLoading, setIsExcelLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        const {startDate, endDate, serviceOpeningTypeCode, trainerSectorId} = config;
        return httpRequest<InspectionRateListRequest, InspectionRateListResponse>({
            method: "GET",
            url: "/lotte/fms/performance",
            params: {
                startDate,
                endDate,
                serviceOpeningTypeCode,
                trainerSectorId,
                buildingId: 0,
                sectorId: 0,
            },
            timeout: 60000,
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const excel = () => {
        setIsExcelLoading(true);
        const {startDate, endDate, serviceOpeningTypeCode, trainerSectorId} = config;
        return httpRequest<InspectionRateListRequest, Blob>({
            method: "GET",
            url: "/lotte/fms/performance/exceldownload",
            params: {
                startDate,
                endDate,
                serviceOpeningTypeCode,
                trainerSectorId,
                buildingId: 0,
                sectorId: 0,
            },
            responseType: "blob",
            timeout: 60000,
        })
            .submit()
            .then((res) => {
                // 파일 다운로드 처리
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                // 파일명추출
                const contentType = res.headers["content-type"];
                let fileName = "downloaded_file";
                if (contentType) {
                    const matches = /filename=([^;]*)/.exec(contentType);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].trim().replace(/['"]/g, "");
                    }
                }
                link.setAttribute("download", decodeURIComponent(fileName));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((e) => console.log(e))
            .finally(() => setIsExcelLoading(false));
    };
    return {submitList: submit, list: response, excel, isLoading, isExcelLoading, error};
};

const useInspectionRateOptionList = () => {
    const [trainerSectorList, setTrainerSectorList] = useState<TrainerSectorListType[]>([]);
    const [storeFormList, setStoreFormList] = useState<StoreFormListType[]>([]);

    const trainer = () => {
        return httpRequest<null, TrainerSectorListType[]>({
            method: "GET",
            url: "/common/trainerSector",
        })
            .submit()
            .then((res) => {
                setTrainerSectorList(res.data);
            })
            .catch((e) => console.log(e));
    };

    const storeForm = () => {
        return httpRequest<null, StoreFormListType[]>({
            method: "GET",
            url: "/common/detailCodes?groupCd=LC006",
        })
            .submit()
            .then((res) => setStoreFormList(res.data))
            .catch((e) => console.log(e));
    };

    useEffect(() => {
        trainer();
        storeForm();
    }, []);

    return {trainerSectorList, storeFormList};
};

export {useInspectionRateList, useInspectionRateOptionList};
