import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {faCalendar} from "@fortawesome/free-regular-svg-icons";
import {faAward, faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DatePicker, Toast} from "antd-mobile";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";

import {UseMobileCheckList} from "~/data/check/mobile/use-mobile-check-list";
import {MobileCheckReport} from "~/data/check/mobile/use-mobile-check-list.interface";
import {useUser} from "~/data/user";

import styles from "./list.module.scss";

const cx = classNames.bind(styles);

const ListPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [visibleDatePicker, setVisibleDatePicker] = useState<boolean>(false);
    const [date, setDate] = useState<Dayjs>(dayjs(searchParams.get("date") || dayjs()));

    const [openIndex, setOpenIndex] = useState<number>(-1);

    const pageTitle: string = useMemo(() => {
        const type = searchParams.get("type");
        if (type === "legal") return "법정점검";
        else if (type === "checklist") return "체크리스트";
        else if (type === "extra") return "일반점검";
        else if (type === "normal") return "추가점검";
        else return "";
    }, [searchParams.get("type")]);

    const category: string = useMemo(() => {
        const type = searchParams.get("type");
        if (type === "legal") return "LEGAL";
        else if (type === "checklist") return "CHECK";
        else if (type === "extra") return "ADD";
        else if (type === "normal") return "NORMAL";
        else return "";
    }, [searchParams.get("type")]);

    useEffect(() => {
        searchParams.set("date", date.format("YYYY-MM-DD"));
        setSearchParams(searchParams, {replace: true});
        Toast.show(date.format("YYYY-MM-DD"));
    }, [date, searchParams.get("type")]);

    const {user} = useUser();

    const {loading, list} = UseMobileCheckList({
        buildingId: user!.buildingId,
        orderDate: searchParams.get("date") as string,
        employeeId: user!.userId,
        category: category,
    });

    const mappedList = useMemo(() => {
        const mapped = new Map<string, Array<MobileCheckReport>>();
        list.map((row) => {
            const getData = mapped.get(row.reportName);
            if (!Array.isArray(getData)) mapped.set(row.reportName, []);

            mapped.set(row.reportName, [...(mapped.get(row.reportName) as Array<MobileCheckReport>), row]);
        });
        return mapped;
    }, [list]);

    const navigate = useNavigate();

    const handleCheckClick = (check: MobileCheckReport) => {
        const isTableView = check.reportName.replaceAll(" ", "").indexOf("수변전") > -1;
        navigate(
            `/mobile/check/${isTableView ? "table" : "view"}/${check.regionId}/${check.checkFormIdArr}/${date.format("YYYY-MM-DD")}`,
        );
    };

    return (
        <div className={cx("container")}>
            <h1 className={cx("title")}>{pageTitle}</h1>
            <div className={cx("button-container")}>
                <button
                    className={cx("btn-with-icon")}
                    onClick={() => {
                        setDate((prev) => prev.add(-1, "day"));
                    }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button
                    className={cx("btn-with-icon")}
                    onClick={() => {
                        setVisibleDatePicker(true);
                    }}
                >
                    <FontAwesomeIcon icon={faCalendar} />
                </button>
                <DatePicker
                    visible={visibleDatePicker}
                    onClose={() => {
                        setVisibleDatePicker(false);
                    }}
                    precision="day"
                    onConfirm={(val: Date) => {
                        setDate(dayjs(val));
                    }}
                    confirmText="확인"
                    cancelText="취소"
                />
                <button
                    className={cx("btn-with-icon")}
                    onClick={() => {
                        setDate((prev) => {
                            if (+new Date(prev.format("YYYY-MM-DD")) < +new Date(dayjs().format("YYYY-MM-DD"))) {
                                return prev.add(1, "day");
                            } else return prev;
                        });
                    }}
                >
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
            <div className={cx("date")}>{searchParams.get("date")}</div>
            <table className={cx("table")}>
                <colgroup>
                    <col />
                    <col />
                    <col width={60} />
                </colgroup>
                <thead>
                    <tr>
                        <th colSpan={2}>점검명</th>
                        <th>점검시간</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.from(mappedList).map((values, titleIndex) => {
                        const value = values[1];
                        const cnts = {
                            allCnt: 0,
                            beforeCnt: 0,
                            ingCnt: 0,
                            endCnt: 0,
                        };
                        value.map((item) => {
                            cnts.allCnt += item.allCnt;
                            cnts.beforeCnt += item.beforeCnt;
                            cnts.ingCnt += item.ingCnt;
                            cnts.endCnt += item.endCnt;
                        });
                        return (
                            <Fragment key={date.format("YYYY-MM-DD") + ":" + values[0]}>
                                <tr>
                                    <td
                                        rowSpan={2}
                                        colSpan={4}
                                        onClick={() => {
                                            setOpenIndex((prevIndex) => {
                                                return prevIndex - titleIndex === 0 ? -1 : titleIndex;
                                            });
                                        }}
                                    >
                                        <p className={cx("reportName")}>
                                            <span className={cx("reportTitle")}>
                                                {titleIndex + 1}. {values[0]}
                                                {cnts.allCnt === cnts.endCnt && (
                                                    <FontAwesomeIcon icon={faAward} className={cx("award")} />
                                                )}
                                            </span>
                                            <span
                                                className={cx("reportCount", {
                                                    done: cnts.allCnt === cnts.endCnt,
                                                })}
                                            >
                                                {cnts.endCnt}/{cnts.allCnt}
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                                <tr />
                                {titleIndex === openIndex &&
                                    value.map((item, index) => (
                                        <Fragment key={date.format("YYYY-MM-DD") + ":" + values[0] + item.regionName}>
                                            <tr onClick={() => handleCheckClick(item)}>
                                                <td />
                                                <td>
                                                    <p className={cx("regionName")}>
                                                        {index + 1}. {item.regionName}
                                                        <br />
                                                    </p>
                                                    <p className={cx("notiTime")}>
                                                        <span>전체: {item.allCnt}</span>
                                                        <span>점검전: {item.beforeCnt}</span>
                                                        <span>점검중: {item.ingCnt}</span>
                                                        <span>완료: {item.endCnt}</span>
                                                    </p>
                                                </td>
                                                <td className={cx("center")}>
                                                    {item.notiTimeArr.split(",").map((time) => (
                                                        <p
                                                            key={time}
                                                            className={cx({
                                                                done:
                                                                    item.endNotitimeArr?.split(",").indexOf(time) > -1,
                                                            })}
                                                        >
                                                            {time.slice(0, 5)}
                                                        </p>
                                                    ))}
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ))}
                            </Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export {ListPage};
