import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import classNames from "classnames/bind";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {CnTable} from "~/components/cn-table/cn-table";
import {PageTitle} from "~/components/page-title";
import {deleteHistory, stopTask, useTasks} from "~/data/check/task/use-tasks";
import {Task} from "~/data/check/task/use-tasks.interface";
import {useCheckDev} from "~/utills/useCheckDev";

import styles from "./check-history.module.scss";

const cx = classNames.bind(styles);

const CheckHistoryPage = () => {
    const {type, dev} = useParams<{type: "checklist" | "extra" | "legal" | "normal"; dev: string}>();
    const {t, i18n} = useTranslation();

    const title = useMemo(() => {
        if (type === "legal") return t("page.inspection.legal-inspection");
        else if (type === "checklist") return t("page.inspection.checklist");
        else if (type === "normal") return t("page.inspection.additional-inspection");
        else if (type === "extra") return t("page.inspection.general-inspection");
    }, [type, i18n.language]);

    const {tasks, totalCount, loading, refresh} = useTasks(type);
    const isDev = useCheckDev();

    const columns: ColumnsType = useMemo(() => {
        const col: ColumnsType = [
            {
                title: "No",
                key: "index",
                render: (_, __, index) => Number(index + 1).toLocaleString(),
                align: "center",
            },
            {
                title: t("page.inspection.inspection-name"),
                dataIndex: "checkFormName",
                key: "checkFormName",
                align: "center",
            },
            {
                title: t("page.inspection.area"),
                dataIndex: "regionName",
                key: "regionName",
                align: "center",
            },
            {
                title: t("page.inspection.perform"),
                dataIndex: "pattern",
                key: "pattern",
                align: "center",
            },
            {
                title: t("page.inspection.inspection-duration"),
                dataIndex: "notiTime",
                key: "notiTime",
                align: "center",
            },
            // {
            //     title: t("page.inspection.inspection-period"),
            //     dataIndex: "startDate",
            //     key: "startDate",
            //     align: "center",
            //     render: (_, __, index) =>
            //         dayjs(tasks[index].startDate).format("YYYY-MM-DD") +
            //         " ~ " +
            //         dayjs(tasks[index].endDate).format("YYYY-MM-DD"),
            // },
            {
                title: t("page.inspection.inspection-instruction-datetime"),
                dataIndex: "createDate",
                key: "createDate",
                align: "center",
                render: (data) => dayjs(data).format("YYYY-MM-DD"),
            },
        ];

        col.push({
            title: t("page.inspection.stop"),
            dataIndex: "checkTaskId",
            key: "checkTaskId1",
            align: "center",
            render: (data, row) => (
                <Button
                    onClick={() => (type === "normal" || type === "extra" ? handleStop(row) : handleDelete(row))}
                    label={t("page.inspection.stop")}
                />
            ),
        });

        return col;
    }, [type, tasks, i18n.language]);

    const handleStop = (row: Task) => {
        Swal.fire({
            title: "중지하시겠습니까?",
            html:
                "본사에서 이행률진행상황을 파악하는 점검은 다음과 같습니다.<br/><br/>" +
                "[법정점검 / 체크리스트 / 추가점검]<br/><br/>" +
                "위 점검 항목과 일반점검의 항목이 동일한 경우에는<br/>" +
                "일반점검의 점검표를 삭제하여 주시기 바라며<br/>" +
                "무분별한 지시이력 삭제는 지양해주시기 바랍니다.",
            showCancelButton: true,
            confirmButtonText: "중지하기",
            cancelButtonText: "취소하기",
            customClass: {
                popup: cx("alert-container"),
            },
        })
            .then((result) => {
                if (result.isConfirmed) {
                    stopTask(row.checkTaskId).then(() => {
                        Swal.fire({
                            title: "중지되었습니다.",
                            cancelButtonText: "닫기",
                        });
                    });
                }
            })
            .finally(() => {
                setTimeout(refresh, 500);
            });
    };
    const handleDelete = (row: Task) => {
        Swal.fire({
            title: "중지하시겠습니까?",
            html:
                "본사에서 이행률진행상황을 파악하는 점검은 다음과 같습니다.<br/><br/>" +
                "[법정점검 / 체크리스트 / 추가점검]<br/><br/>" +
                "위 점검 항목과 일반점검의 항목이 동일한 경우에는<br/>" +
                "일반점검의 점검표를 삭제하여 주시기 바라며<br/>" +
                "무분별한 지시이력 삭제는 지양해주시기 바랍니다.",
            showCancelButton: true,
            confirmButtonText: "중지하기",
            cancelButtonText: "취소하기",
            customClass: {
                popup: cx("alert-container"),
            },
        })
            .then((result) => {
                if (result.isConfirmed) {
                    deleteHistory(row.checkHistoryId).then(() => {
                        Swal.fire({
                            title: "중지되었습니다.",
                            cancelButtonText: "닫기",
                        });
                    });
                }
            })
            .finally(() => {
                setTimeout(refresh, 500);
            });
    };

    return (
        <div>
            <PageTitle>{title + " " + t("page.inspection.instruction-history")}</PageTitle>
            <div>
                <CnTable
                    page={1}
                    onPageChange={() => null}
                    totalCount={totalCount}
                    perPage={totalCount}
                    dataSource={tasks?.map((row: any) => {
                        row["key"] = row.checkTaskId;
                        return row;
                    })}
                    pagination={false}
                    loading={loading}
                    columns={columns}
                />
            </div>
        </div>
    );
};

export {CheckHistoryPage};
