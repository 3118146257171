import {ChangeEvent, useEffect, useState} from "react";
import {faDownload, faPaperclip, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button as ButtonAntd, DatePicker, Input, InputNumber, List, Radio, Select, Spin, Upload} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {MergeGridItem} from "~/components/merge/merge-grid-item/merge-grid-item";
import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title/page-title";
import {useCostFilterTypeList} from "~/data/cost/common/use-cost-filter";
import {useFacilityList} from "~/data/cost/common/use-facility-list";
import {
    useCostFacilityDelete,
    useCostFacilityRegistration,
} from "~/data/cost/facility-maintenance/modal/use-cost-facility-cud";
import {useCostFacilityData} from "~/data/cost/facility-maintenance/modal/use-cost-facility-data";
import {
    useCostFacilityMemberApproval,
    useCostFacilityMemberConfirm,
    useCostFacilityMemberFix,
} from "~/data/cost/facility-maintenance/modal/use-cost-facility-member";
import {
    useCostFacilityReportDelete,
    useCostFacilityReportDownload,
} from "~/data/cost/facility-maintenance/modal/use-cost-facility-report";
import {formatCost} from "~/utills/formatCost";
import {useLocalStorage} from "~/utills/useLocalStorage";

import {CostMonthlyModalInput, CostMonthlyModalProps} from "./cost-monthly.modal.interface";

import styles from "./cost-monthly.modal.module.scss";
import stylesGrid from "~/components/merge/merge-grid-item/merge-grid-item.module.scss";

const cx = classNames.bind(styles);
const gridCx = classNames.bind(stylesGrid);

const CostMonthlyModal = ({costFacilityId, close, division, submitTableList}: CostMonthlyModalProps) => {
    const [inputs, setInputs] = useState<CostMonthlyModalInput>({
        year: dayjs(new Date()),
        month: dayjs(new Date()),
        yearProcessId: null, // 공정
        yearConstructName: "", // 공사건명
        yearMachineLinkYn: "Y", // 설비연동
        monthConstructGoYn: "Y", // 선진행 공사여부
        isSubmitReport: "Y",
        yearMachineId: [], // 설비
        monthConstructDate: null, // 공사예가 제출일
        monthConstructGoReason: "", // 공사사유
        monthConstructCompany: "", // 공사예가 제출업체
        monthConstructCost: 0, //금액
        storeContributionAmount: 0, // 마트 부담금액
        offsetAmount: 0, // 상계처리비용
        monthEstimateFileObj: null, // 견적서
        monthMemo: "", // 비고
    });
    const [fileName, setFileName] = useState<string | null>(null);
    const [buildingId, setBuildingId] = useState<number>(0);
    const [fixedCost, setFixedCost] = useState<number | null>(null);

    const [machineCategory, setMachineCategory] = useState<string>("ALL");

    const {setItem, getItem} = useLocalStorage();
    // 설비 리스트
    const [submitFacilityList, facilityList, getMachineCategory, machineCategoryRes] = useFacilityList(
        buildingId,
        machineCategory,
    );
    // 유형, 공정
    const [submitTypeList, options] = useCostFilterTypeList();
    // 상세정보
    const [submitData, data, isDataLoading] = useCostFacilityData(costFacilityId, 1);
    // 등록
    const [submitRegistration] = useCostFacilityRegistration({
        ...inputs,
        costFacilityId,
        budgetDivision: 1,
        typeId: null,
    });
    // 삭제
    const [submitDelete] = useCostFacilityDelete({costFacilityId, division});
    // 견적서 다운로드
    const [downloadReport] = useCostFacilityReportDownload(costFacilityId);
    // 견적서 삭제
    const [deleteReport] = useCostFacilityReportDelete(costFacilityId);
    // 본사 확인
    const [checkMember] = useCostFacilityMemberConfirm({costFacilityId});
    // 본사 승인
    const [approvalMember] = useCostFacilityMemberApproval({costFacilityId});
    // 본사 확정
    const [fixMember] = useCostFacilityMemberFix({costFacilityId, fixedCost});

    // 지점 수정 시 값 셋팅
    useEffect(() => {
        if (data) {
            setInputs({
                year: dayjs().year(Number(data.year)),
                month: dayjs().month(Number(data.month) - 1),
                isSubmitReport:
                    data.isSubmitReportDescription === "작성"
                        ? "Y"
                        : data.isSubmitReportDescription === "미작성"
                          ? "N"
                          : "ETC",
                yearProcessId: data.yearProcessId,
                yearConstructName: data.yearConstructName,
                yearMachineLinkYn: data.yearMachineLinkYn ?? "N",
                yearMachineId:
                    data.yearMachineId === "0" ? [] : data.yearMachineId.split(",").map((data) => Number(data)),
                monthConstructDate: dayjs(new Date(data.monthConstructDate ?? "")),
                monthConstructCompany: data.monthConstructCompany ?? "",
                monthConstructCost: data.monthConstructCost,
                monthConstructGoReason: data.monthConstructGoReason ?? "",
                monthConstructGoYn: data.monthConstructGoYn ?? "N",
                monthEstimateFileObj: null,
                monthMemo: data.monthMemo,
                offsetAmount: data.offsetAmount,
                storeContributionAmount: data.storeContributionAmount,
            });
            setFileName(data.monthEstimateFile);
            setFixedCost(data.monthConstructCost);
        }
    }, [data]);

    useEffect(() => {
        setBuildingId(JSON.parse(getItem("LM_USER")!).buildingId);
        submitTypeList();
        if (costFacilityId !== 0) submitData();
    }, []);

    useEffect(() => {
        getMachineCategory();
        if (buildingId !== 0) submitFacilityList();
    }, [buildingId]);

    useEffect(() => {
        submitFacilityList();
    }, [machineCategory]);

    useEffect(() => {
        setFileName(inputs.monthEstimateFileObj?.name ?? null);
    }, [inputs.monthEstimateFileObj]);

    // 저장
    const handleConfirm = async () => {
        if (division === "MEMBER") {
            if (data?.state === "M_SUBMIT") {
                await checkMember();
                setTimeout(submitTableList, 500);
                close();
            } else if (data?.state === "M_VERIFY") {
                await approvalMember();
                setTimeout(submitTableList, 500);
                close();
            } else {
                if (fixedCost) {
                    await fixMember();
                    setTimeout(submitTableList, 500);
                    close();
                } else {
                    Swal.fire({
                        text: "필수 입력사항을 확인해주세요.",
                        confirmButtonText: "확인",
                    });
                }
            }
        } else {
            if (
                (inputs.yearMachineLinkYn === "N" || inputs.yearMachineId.length) &&
                inputs.yearProcessId &&
                inputs.yearConstructName &&
                inputs.monthConstructDate &&
                inputs.monthConstructGoReason &&
                inputs.monthConstructCompany &&
                inputs.monthConstructCost
            ) {
                await submitRegistration();
                setTimeout(submitTableList, 500);
                close();
            } else {
                Swal.fire({
                    text: "필수 입력사항을 확인해주세요.",
                    confirmButtonText: "확인",
                });
            }
        }
    };
    const handleDelete = () => {
        Swal.fire({
            text: "삭제하시겠습니까?",
            showCancelButton: true,
            cancelButtonText: "취소",
            confirmButtonText: "확인",
        }).then((info) => {
            if (info.isConfirmed) {
                submitDelete();
                setTimeout(submitTableList, 500);
                close();
            }
        });
    };
    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        setInputs((prev) => ({...prev, [e.target.name]: e.target.value}));
    };

    return (
        <SideModal
            onClose={close}
            confirmLabel={
                data?.state === "M_CONFIRM"
                    ? undefined
                    : division === "MEMBER"
                      ? data?.state === "M_SUBMIT"
                          ? "본사확인"
                          : data?.state === "M_VERIFY"
                            ? "승인"
                            : "확정"
                      : "저장"
            }
            onConfirm={handleConfirm}
            extraButton={<Button label={"삭제"} onClick={handleDelete} />}
            nonePrevent
        >
            <PageTitle>월 시행계획 상세</PageTitle>
            {isDataLoading ? (
                <div className={cx("loading")}>
                    <Spin size="large" />
                </div>
            ) : (
                <div className={cx("form-container")}>
                    <div className={cx("detail-content", {member: division === "MEMBER"})}>
                        <MergeGridItem name={"연도"}>
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                data?.year
                            ) : (
                                <DatePicker
                                    placeholder={"연도 선택"}
                                    picker={"year"}
                                    style={{width: "120px"}}
                                    value={inputs.year}
                                    allowClear={false}
                                    onChange={(e) => setInputs((prev) => ({...prev, year: e}))}
                                    getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                                />
                            )}
                        </MergeGridItem>
                        <MergeGridItem name={"월"}>
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                data?.month
                            ) : (
                                <DatePicker
                                    placeholder={"월 선택"}
                                    popupClassName="no-header"
                                    picker={"month"}
                                    format={"MM"}
                                    style={{width: "120px"}}
                                    value={inputs.month}
                                    onChange={(e) => setInputs((prev) => ({...prev, month: e}))}
                                    allowClear={false}
                                    cellRender={(date) => (
                                        <div className="ant-picker-cell-inner">{dayjs(date).format("MM")}</div>
                                    )}
                                    getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                                />
                            )}
                        </MergeGridItem>
                        <MergeGridItem
                            name={
                                <div className={cx("two-line-text")}>
                                    <span>선시행 보고서</span>
                                    <br />
                                    <span>작성 여부</span>
                                </div>
                            }
                        >
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                data?.isSubmitReportDescription
                            ) : (
                                <Select
                                    style={{width: "85%"}}
                                    options={[
                                        {label: "작성", value: "Y"},
                                        {label: "미작성", value: "N"},
                                        {label: "기타", value: "ETC"},
                                    ]}
                                    value={inputs.isSubmitReport}
                                    status={inputs.isSubmitReport === null ? "error" : ""}
                                    onChange={(e) => setInputs((prev) => ({...prev, isSubmitReport: e}))}
                                    placeholder={"유형선택"}
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                />
                            )}
                        </MergeGridItem>
                        <MergeGridItem name={"공정"}>
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                data?.yearProcessName
                            ) : (
                                <Select
                                    style={{width: "85%"}}
                                    options={options.processList}
                                    value={inputs.yearProcessId}
                                    status={inputs.yearProcessId === null ? "error" : ""}
                                    onChange={(e) => setInputs((prev) => ({...prev, yearProcessId: e}))}
                                    placeholder={"공정선택"}
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                />
                            )}
                        </MergeGridItem>
                        {division === "MEMBER" && (
                            <MergeGridItem span name={"지점"}>
                                {data?.buildingName}
                            </MergeGridItem>
                        )}
                        <MergeGridItem name={"공사건명"} span extraClassName={division === "MEMBER" ? "text-left" : ""}>
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                data?.yearConstructName
                            ) : (
                                <Input
                                    className={cx("input")}
                                    value={inputs.yearConstructName}
                                    status={inputs.yearConstructName === "" ? "error" : ""}
                                    name={"yearConstructName"}
                                    onChange={handleChangeInput}
                                />
                            )}
                        </MergeGridItem>
                        <MergeGridItem name={"설비연동"}>
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                data?.yearMachineLinkYnDesc
                            ) : (
                                <Radio.Group
                                    value={inputs.yearMachineLinkYn}
                                    onChange={(e) =>
                                        setInputs((prev) => ({...prev, yearMachineLinkYn: e.target.value}))
                                    }
                                >
                                    <Radio value={"Y"}>연동</Radio>
                                    <Radio value={"N"}>비연동</Radio>
                                </Radio.Group>
                            )}
                        </MergeGridItem>
                        <MergeGridItem
                            name={
                                <div className={cx("two-line-text")}>
                                    <span> 선진행</span>
                                    <br />
                                    <span> 공사 여부</span>
                                </div>
                            }
                        >
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                data?.monthConstructGoYnDesc
                            ) : (
                                <Radio.Group
                                    value={inputs.monthConstructGoYn}
                                    onChange={(e) =>
                                        setInputs((prev) => ({...prev, monthConstructGoYn: e.target.value}))
                                    }
                                >
                                    <Radio value={"Y"}>진행</Radio>
                                    <Radio value={"N"}>미진행</Radio>
                                </Radio.Group>
                            )}
                        </MergeGridItem>
                        <MergeGridItem name={"설비 카테고리"} span>
                            <Select
                                showSearch
                                style={{width: "95%"}}
                                placeholder="설비 카테고리 선택"
                                value={machineCategory}
                                onChange={(e) => setMachineCategory(e)}
                                options={machineCategoryRes}
                                filterOption={(input, option) => {
                                    if (option) {
                                        return option.label.toLowerCase().includes(input.toLowerCase());
                                    }
                                    return false;
                                }}
                                disabled={
                                    inputs.yearMachineLinkYn === "N" ||
                                    !(
                                        data?.state === "Y_CONFIRM" ||
                                        data?.state === "Y_VERIFY" ||
                                        costFacilityId === 0 ||
                                        data?.state === "M_SUBMIT" ||
                                        data?.state === "M_VERIFY"
                                    )
                                }
                                getPopupContainer={(trigger) => trigger.parentNode}
                            />
                        </MergeGridItem>
                        <MergeGridItem name={"설비"} span>
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                data?.yearMachineName
                            ) : (
                                <Select
                                    style={{width: "95%"}}
                                    mode={"multiple"}
                                    placeholder="설비 선택"
                                    disabled={inputs.yearMachineLinkYn === "N"}
                                    status={
                                        inputs.yearMachineId.length
                                            ? ""
                                            : inputs.yearMachineLinkYn === "N"
                                              ? ""
                                              : "error"
                                    }
                                    value={inputs.yearMachineId}
                                    onChange={(e) => setInputs((prev) => ({...prev, yearMachineId: e}))}
                                    allowClear
                                    // 검색 기능
                                    filterOption={(input, option) => {
                                        if (option) {
                                            return option.label.toLowerCase().includes(input.toLowerCase());
                                        }
                                        return false;
                                    }}
                                    options={
                                        facilityList?.map((data) => ({
                                            label: data.machineName,
                                            value: data.machineId,
                                        })) ?? []
                                    }
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                />
                            )}
                        </MergeGridItem>
                        {costFacilityId !== 0 && <MergeGridItem name={"상태"}>{data?.monthVerifyDesc}</MergeGridItem>}
                        <MergeGridItem
                            span={costFacilityId === 0}
                            name={
                                <div className={cx("two-line-text")}>
                                    <span>공사예가</span>
                                    <br />
                                    <span>제출일</span>
                                </div>
                            }
                        >
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                data?.monthConstructDate
                            ) : (
                                <DatePicker
                                    className={cx("datepicker")}
                                    style={{width: costFacilityId === 0 ? "95%" : "80%"}}
                                    status={inputs.monthConstructDate === null ? "error" : ""}
                                    value={inputs.monthConstructDate}
                                    onChange={(e) => setInputs((prev) => ({...prev, monthConstructDate: e}))}
                                    getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                                />
                            )}
                        </MergeGridItem>
                        <MergeGridItem
                            name={
                                <div className={cx("two-line-text")}>
                                    <span>공사진행</span>
                                    <br />
                                    <span>사유</span>
                                </div>
                            }
                            span
                        >
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                data?.monthConstructGoReason
                            ) : (
                                <Input
                                    className={cx("input")}
                                    status={inputs.monthConstructGoReason === "" ? "error" : ""}
                                    value={inputs.monthConstructGoReason}
                                    name={"monthConstructGoReason"}
                                    onChange={handleChangeInput}
                                />
                            )}
                        </MergeGridItem>
                        <MergeGridItem
                            name={
                                <div className={cx("two-line-text")}>
                                    <span>공사예가</span>
                                    <br />
                                    <span>제출업체</span>
                                </div>
                            }
                            span
                        >
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                data?.monthConstructCompany
                            ) : (
                                <Input
                                    className={cx("input")}
                                    value={inputs.monthConstructCompany}
                                    status={inputs.monthConstructCompany === "" ? "error" : ""}
                                    name={"monthConstructCompany"}
                                    onChange={handleChangeInput}
                                />
                            )}
                        </MergeGridItem>
                        <MergeGridItem
                            name={"금액"}
                            span={!(data?.state === "M_APPROVAL" || data?.state === "M_CONFIRM")}
                        >
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                data?.monthConstructCost?.toLocaleString() ?? 0
                            ) : (
                                <InputNumber
                                    className={cx("input")}
                                    value={inputs.monthConstructCost}
                                    status={
                                        inputs.monthConstructCost !== null
                                            ? inputs.monthConstructCost <= 0
                                                ? "error"
                                                : ""
                                            : "error"
                                    }
                                    name={"monthConstructCost"}
                                    controls={false}
                                    formatter={(value) => formatCost(value)}
                                    onChange={(value) => setInputs((prev) => ({...prev, monthConstructCost: value}))}
                                />
                            )}
                        </MergeGridItem>
                        {(data?.state === "M_APPROVAL" || data?.state === "M_CONFIRM") && (
                            <MergeGridItem require name={"반영금액"}>
                                {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                    data?.state === "M_CONFIRM" ? (
                                        data.fixedCost?.toLocaleString() ?? 0
                                    ) : (
                                        <InputNumber
                                            className={cx("input")}
                                            value={fixedCost}
                                            status={fixedCost === null ? "error" : ""}
                                            name={"fixedCost"}
                                            controls={false}
                                            formatter={(value) => formatCost(value)}
                                            onChange={(value) => setFixedCost(value)}
                                        />
                                    )
                                ) : (
                                    data.fixedCost?.toLocaleString() ?? 0
                                )}
                            </MergeGridItem>
                        )}
                        {/* <MergeGridItem
                            name={
                                <div className={cx("two-line-text")}>
                                    <span>마트</span>
                                    <br />
                                    <span>부담금액</span>
                                </div>
                            }
                        >
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                data?.storeContributionAmount.toLocaleString() ?? 0
                            ) : (
                                <InputNumber
                                    className={cx("input")}
                                    value={inputs.storeContributionAmount}
                                    controls={false}
                                    formatter={(value) => formatCost(value)}
                                    onChange={(value) =>
                                        setInputs((prev) => ({...prev, storeContributionAmount: value}))
                                    }
                                />
                            )}
                        </MergeGridItem>
                        <MergeGridItem name={"상계처리금액"}>
                            {" "}
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                data?.offsetAmount.toLocaleString() ?? 0
                            ) : (
                                <InputNumber
                                    className={cx("input")}
                                    value={inputs.offsetAmount}
                                    controls={false}
                                    formatter={(value) => formatCost(value)}
                                    onChange={(value) => setInputs((prev) => ({...prev, offsetAmount: value}))}
                                />
                            )}
                        </MergeGridItem> */}
                        <MergeGridItem name={"견적서"} span extraClassName={"upload-container"}>
                            <div className={gridCx("file-container")}>
                                <Upload.Dragger
                                    className={gridCx("upload-dragger", "cost")}
                                    name={"file"}
                                    maxCount={1}
                                    disabled={data?.state === "M_CONFIRM" || data?.state === "M_APPROVAL"}
                                    showUploadList={false}
                                    beforeUpload={(e) => {
                                        setInputs((prev) => ({...prev, monthEstimateFileObj: e}));
                                        return false;
                                    }}
                                >
                                    {fileName ? (
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={[fileName]}
                                            className={gridCx("file-list")}
                                            renderItem={(file, index) => (
                                                <List.Item
                                                    className={gridCx("file-list-item")}
                                                    key={index}
                                                    actions={[
                                                        <ButtonAntd
                                                            type="link"
                                                            key={index}
                                                            icon={<FontAwesomeIcon icon={faDownload} />}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                downloadReport();
                                                            }}
                                                            style={{
                                                                display: costFacilityId === 0 ? "none" : "",
                                                            }}
                                                        />,
                                                        <ButtonAntd
                                                            type="link"
                                                            danger
                                                            key={index}
                                                            icon={<FontAwesomeIcon icon={faTrashCan} />}
                                                            style={{
                                                                display:
                                                                    division === "MEMBER" || data?.state === "M_CONFIRM"
                                                                        ? "none"
                                                                        : "",
                                                            }}
                                                            onClick={async (e) => {
                                                                e.stopPropagation();
                                                                Swal.fire({
                                                                    html: "파일을 삭제하시겠습니까? <br/> 파일삭제는 즉시 저장됩니다",
                                                                    showCancelButton: true,
                                                                    confirmButtonText: "확인",
                                                                    cancelButtonText: "취소",
                                                                }).then(async (info) => {
                                                                    if (info.isConfirmed) {
                                                                        if (costFacilityId !== 0) {
                                                                            await deleteReport();
                                                                        }
                                                                        setFileName(null);
                                                                        setInputs((prev) => ({
                                                                            ...prev,
                                                                            monthEstimateFileObj: null,
                                                                        }));
                                                                    }
                                                                });
                                                            }}
                                                        />,
                                                    ]}
                                                >
                                                    <List.Item.Meta
                                                        avatar={<FontAwesomeIcon icon={faPaperclip} />}
                                                        title={fileName.split("/").pop()}
                                                        className={gridCx("file-list-item-meta")}
                                                        style={{textAlign: "left"}}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    ) : (
                                        <>
                                            <p>드래그하여 파일을 업로드 하거나 파일을 선택하세요</p>
                                        </>
                                    )}
                                </Upload.Dragger>
                            </div>
                        </MergeGridItem>
                        <MergeGridItem name={"비고"} span>
                            {division === "MEMBER" || data?.state === "M_CONFIRM" ? (
                                <pre className={cx("text-area-text")}> {data?.monthMemo}</pre>
                            ) : (
                                <Input.TextArea
                                    className={cx("text-area")}
                                    style={{resize: "none"}}
                                    value={inputs.monthMemo}
                                    onChange={(e) => setInputs((prev) => ({...prev, monthMemo: e.target.value}))}
                                />
                            )}
                        </MergeGridItem>
                    </div>
                </div>
            )}
        </SideModal>
    );
};

export {CostMonthlyModal};
